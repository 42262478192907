import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
// import Header from './Header';
import UserHeader from './UserHeader';
import PublicHeader from './PublicHeader';
import TextField from '@material-ui/core/TextField';
import progressPng from '../assets/progress.png';
import lock from '../assets/lock.png';
import clsx from 'clsx';
import { InputBase, IconButton, CircularProgress } from '@material-ui/core';
import SafeTechSnackbar from './SafeTechSnackbar';

var MSG = '';
var ERROR = 0;

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	roundedBox: {
		height: '200px',
		width: '200px'
	},
	boxIcon: {
		height: '40px',
		width: '40px'
	},
	balanceNumber: {
		textAlign: 'center',
		font: 'Bold Roboto',
		letterSpacing: '0px',
		textShadow: '0px 2px 5px #00000029',
		opacity: 1
	},
	contentBackground:{
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    }
});

class UserProfile extends Component {

	constructor(props) {
		super(props)
		this.state = {
            user_id: '',
			user_name: '',
			first_name: '',
			last_name: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			phone: '',
			email: '',
			credit: '',
			min_credit: '',
			credit_display: '',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			modalOpen: false,
			password: '',
			new_password: '',
			confirm_password: '',
			BUTTONS: true,
			user_detail_spinner: false,
			profile_spinner: false,
			password_spinner: false,
			show: false
		}
		this.addressReference = React.createRef();
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	componentDidUpdate(prevProps) {
		// console.log('componentDidUpdate ::', prevProps);
		// console.log('this.addressReference.current ::', this.addressReference.current);
		if(this.addressReference.current){
			const autocompleteFormField = this.addressReference.current;
			const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
				types: ['address'],
			});
			window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
				this.setState({ user_street_number: '', address: '' , zip: '', state: '', city: '' })
				const place = autocomplete.getPlace();
				// console.log('PLACE ===>', place);
				if (place.address_components != null) {
					place.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ user_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setState({ address: this.state.user_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setState({ zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setState({ state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setState({ city: value.long_name })
						}
					})
				}
			});
		}
	}

	componentDidMount() {
		console.log('componentDidMount userProfile : ', this.state);
		// if (this.props.state.token === '' || this.props.state.user_type === 'Admin') {
		// 	window.location.href = "/"
		// }
		// else {
			const pathName = this.props.location.pathname.toLowerCase()
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true, snackVariant: 'error' })
				this.props.UpdateMSG('')
			}
			if (this.props.state.token !== '' && pathName === '/userprofile' && this.props.state.user_type !== 'Admin') {
				console.log('if pathName is /userprofile')
				this.fetchCurrentUserDetails(this.props.state.user_id);
			}
			else if (this.props.match.params.userName !== undefined && this.props.match.params.adminToken !== undefined) {
				console.log('where both are not undefined...')
				if (this.props.state.token !== '' && this.props.state.user_type === 'Admin') {
					// console.log('external url token is not blank and user type is admin...')
					this.setState({ show: false });
					window.location.href = "/AccountList";
				}else{
					// console.log('external url token is blank and user type is not admin...')
					this.fetchUserId(this.props.match.params.userName);
				}
			}else{
				this.setState({ show: false });
				window.location.href = "/"
			}
		// }
	}

	handleClickOpen = () => {
		// console.log("user id to set new pass : ",this.props.state.user_id);
		this.setState({ modalOpen: true })
	}

	handleClose = () => {
		this.setState({ modalOpen: false, new_password: '', confirm_password: '' })
	}

	setNewPassword = (event) => {
		event.preventDefault()
		// console.log("here : ",this.state.password,this.state.confirm_password);
		if (this.state.new_password == '') {
			this.setState({ snackMsg: "Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.confirm_password == '') {
			this.setState({ snackMsg: "Confirm Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.new_password !== this.state.confirm_password) {
			this.setState({ snackMsg: "Confirm Password isn't matched!!.", snack: true, snackVariant: 'error' })
		}
		else {
			this.actionResetPasswordClick()
		}
	}

	async actionResetPasswordClick() {
		if (navigator.onLine) {
			var Data = {
				user_id: this.props.state.user_id,
				password: this.state.new_password,
				confirm_change_password: 1
			}
			this.setState({ BUTTONS: false, password_spinner: true })
			return fetch(URLHelper.SetNewPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log(res)
						const { message } = res;
						this.setState({ snackMsg: message, snack: true, snackVariant: 'success', new_password: '', confirm_password: '', BUTTONS: true, password_spinner: false })
					}
					else {
						this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', new_password: '', confirm_password: '', BUTTONS: true, password_spinner: false })
					}
				})
				.catch((error) => {
					//console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true, password_spinner: false })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error', BUTTONS: true, password_spinner: false })
		}
	}

	async fetchUserId(userName) {
        if (navigator.onLine) {
            return fetch(URLHelper.GetUserId + "/" + userName, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.match.params.adminToken
                },
            }).then((Response) => Response.json())
                .then((res) => {
                    if (res.status) {
                        let userData = res.data[0];
                        // console.log('got user id with other details :->',userData)
                        this.setState({ user_id: userData.user_id });
                        this.fetchCurrentUserDetails(userData.user_id);
                    }
                    else {
                        console.log('invalid user credentials at time of edit profile')
						this.setState({ spinner: 'none', snackMsg: "Invalid user credentials.", snack: true, show: false })
                        window.location.href = "/";
                    }
                })
                .catch((error) => {
                    // console.log("Error==>" + error)
                    this.setState({ spinner: 'none', snackMsg: "Something went wrong here.", snack: true, show: false })
                    console.log('Something went wrong at time of edit profile')
                    window.location.href = "/";
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, show: false })
            console.log('Internet Not Available. Try Again!!')
            window.location.href = "/";
        }
    }
	
	async fetchCurrentUserDetails(accountid) {
        if (navigator.onLine) {
			this.setState({ user_detail_spinner: true })
            let token = (this.props.match.params.adminToken !== undefined) ? this.props.match.params.adminToken : this.props.state.token;
            // let authorization = 'Bearer ' + token;
            // console.log(this.props.match.params.adminToken !== undefined ? 'true@@@@@@@@@' : 'false@@@@@@@');
            // console.log('authorization :', authorization);
            return fetch(URLHelper.GetUserDetail + "/" + accountid, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
            }).then((Response) => Response.json())
                .then((res) => {
                    if (res.status) {
                        let userData = res.data[0];
                        // console.log('result user details : ', userData);
                        this.setState({
                            show: true,
                            first_name: userData.first_name,
                            last_name: userData.last_name,
                            address: userData.address,
                            city: userData.city,
                            state: userData.state,
                            zip: userData.zip,
                            phone: userData.phone_no,
							email: userData.email,
							user_detail_spinner: false
                        })
                    }
                    else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
                    }
                })
                .catch((error) => {
                    // console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error' })
                })
        }
        else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
	}
	
	profileFormSubmit = (event) => {
		event.preventDefault()
		console.log("profileFormSubmit called...")
		if ((this.state.first_name != '' && this.state.last_name != '' && this.state.phone_no != '' && this.state.address != '' && this.state.city != '' && this.state.state != '' && this.state.zip != '' && this.state.email != '')) {
			if (this.state.phone.length == 10 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
				this.setState({ BUTTONS: false, profile_spinner: true })
				this.editUser()
			}
			else {
				//console.log("SNACK==>" + this.state.snackMsg);
				if (this.state.phone.length != 10) {
					//console.log("Invalid Phone")
					MSG = MSG + "\nInvalid Phone Number."
					ERROR = 1
				}

				if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
					//console.log("Invalid Email")
					MSG = MSG + "\nInvalid Email."
					ERROR = 1
				}

				if (ERROR == 1) {
					//console.log("ERROR")
					this.setState({ snackMsg: MSG, snack: true, snackVariant: 'error' })
				}
			}
		}
		else {
			this.setState({ snackMsg: "Please fill all the details.", snack: true, snackVariant: 'error' })
		}
	}

	editUser = () => {
        if (navigator.onLine) {
			let user_id = (this.props.match.params.userName !== undefined) ? this.state.user_id : this.props.state.user_id;
			let token = (this.props.match.params.adminToken !== undefined) ? this.props.match.params.adminToken : this.props.state.token;
            let Data = {
                //admin_token: this.props.state.token,
                actionby_admin: this.props.state.user_type,
                user_id,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                phone_no: this.state.phone,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                email: this.state.email,
            }
            //console.log('form submit data : ', Data);
            return fetch(URLHelper.EditUser, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
					// console.log('editUser ::', res)
					// console.log('this.props.state ::', this.props.state.credit_balance)
					// console.log('this.props.location.pathname.toLowerCase() ::', this.props.location.pathname.toLowerCase())
					if(this.props.location.pathname.toLowerCase() === '/userprofile'){
						this.setState({ BUTTONS: true, profile_spinner: false, snackMsg: res.message, snack: true, snackVariant: (res.status) ? 'success' : 'error'})
						this.props.CustomerDetail(this.state.phone, this.state.first_name, this.state.last_name, this.props.state.credit_balance, this.state.address, this.state.city, this.state.state, this.state.zip)
					}
					else{
						this.setState({ BUTTONS: true, profile_spinner: false, snackMsg: res.message, snack: true, snackVariant: (res.status) ? 'success' : 'error'})
						setTimeout(() => this.props.history.push("/"), 2000)
					}
                })
                .catch((error) => {
					console.log("Error==>" + error)
                    this.setState({ BUTTONS: true, profile_spinner: false, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ BUTTONS: true, profile_spinner: false, snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    inputHandler = (event, inputID) => {
		let value = event.target.value
		if (inputID == "first_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ first_name: value })
			}
			else {
				//console.log("Not First Name")
			}
		}
		else if (inputID == "last_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ last_name: value })
			}
			else {
				//console.log("Not Last Name")
			}
		}
		else if (inputID == "address") {
			this.setState({ address: value })
		}
		else if (inputID == "city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		// else if (inputID == "phone") {
		// 	if (value.length < 11) {
		// 		if (/^\d+$/.test(value) || value.length == 0) {
		// 			this.setState({ phone: value })
		// 		}
		// 		else {
		// 			//console.log("Not number")
		// 		}
		// 	}
		// }
		else if (inputID == "email") {
			this.setState({ email: value })
		}
		else if (inputID == "new_password") {
			this.setState({ new_password: value })
		}
		else if (inputID == "confirm_password") {
			this.setState({ confirm_password: value })
		}
	}

	hideSnackBar = () => {
		this.setState({ snack: false })
	}

	render() {
		const { classes } = this.props;
		// if (this.state.show) {
			return (
				<div className={clsx((this.props.location.pathname.toLowerCase() !== '/userprofile') && classes.contentBackground, 'h-screen')}>
					<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />

					{/* <Header propsState={this.props.state} /> */}
					{
						(this.props.location.pathname.toLowerCase() === '/userprofile')
						?
						<UserHeader propsState={this.props.state} />
						:
						<PublicHeader propsState={this.props.state} />
					}
					<div>
						<div className="p-2 sm:p-10">
						    <div className='user-body-card px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden shadow-lg bg-white'>
                                <div className="flex pt-4 pb-2">
                                    <span className="float-left text-primary text-sm">Account Profile</span>
                                </div>
								
								<div style={{ width: '100%', alignItems: 'center', display: (this.state.user_detail_spinner ? 'block' : 'none') }}>
									<center><CircularProgress className={classes.progress} /></center>
								</div>
								{
									(!this.state.user_detail_spinner) &&
	                                <form noValidate autoComplete="off" onSubmit={this.profileFormSubmit}>
                                    <div className="py-2">
                                        <Grid container spacing={2} className="pb-4">
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    autoFocus={true}
                                                    id="first_name"
                                                    label="First Name"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.first_name}
                                                    onChange={(event) => this.inputHandler(event, "first_name")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <TextField
                                                    id="last_name"
                                                    label="Last Name"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.last_name}
                                                    onChange={(event) => this.inputHandler(event, "last_name")}
                                                    variant="outlined"
                                                />
                                            </Grid>
											<Grid item xs={12} sm={12} md={12}>
												<input
													id="location"
													placeholder="Search Address"
													type="text"
													margin="normal"
													style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
													ref={this.addressReference}
													variant="outlined"
													className="w-full border-solid border border-gray-500 rounded"
												/>
											</Grid>
                                        </Grid>
                                        <TextField
                                            id="address"
                                            label="Address"
                                            type="text"
                                            className="w-full"
                                            value={this.state.address}
                                            onChange={(event) => this.inputHandler(event, "address")}
                                            variant="outlined"
                                        />
                                        <div className="flex pt-4 pb-2">
                                            <div className="flex-initial w-2/5 pr-2 text-center">
                                                <TextField
                                                    id="city"
                                                    label="City"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.city}
                                                    onChange={(event) => this.inputHandler(event, "city")}
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="flex-initial w-1/5 pr-2 text-center">
                                                <TextField
                                                    id="state"
                                                    label="State"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.state}
                                                    onChange={(event) => this.inputHandler(event, "state")}
                                                    variant="outlined"
                                                />
                                            </div>
                                            <div className="flex-initial w-2/5 text-gray-700 text-center">
                                                <TextField
                                                    id="zip"
                                                    label="Zip"
                                                    type="tel"
                                                    className="w-full"
                                                    value={this.state.zip}
                                                    onChange={(event) => this.inputHandler(event, "zip")}
                                                    variant="outlined"
                                                />
                                            </div>
                                        </div>
                                        <Grid container spacing={2} className="py-2">
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField
                                                    id="phone"
                                                    label="Phone #"
													type="tel"
													disabled={true}
                                                    className="w-full"
                                                    value={this.state.phone}
                                                    onChange={(event) => this.inputHandler(event, "phone")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} >
                                                <TextField
                                                    id="email"
                                                    label="Email"
                                                    type="email"
                                                    className="w-full"
                                                    value={this.state.email}
                                                    onChange={(event) => this.inputHandler(event, "email")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                        </Grid>
                                        {/* <div style={{ width: '100%', alignItems: 'center', display: this.state.infoSpinner }}>
                                            <center><CircularProgress className={classes.progress} /></center>
                                        </div> */}
                                    </div>
                                    
                                    <div className="flex">
                                        <div className="flex-1">
                                        </div>
                                        <div className="flex-grow sm:flex-1">
                                            <div className="float-none sm:float-right">
                                                <button type="button" onClick={() => {
													if(this.props.location.pathname.toLowerCase() === '/userprofile'){
														this.fetchCurrentUserDetails(this.props.state.user_id);
													}else{
														this.fetchCurrentUserDetails(this.state.user_id);
													}
												}} disabled={(!this.state.BUTTONS)} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Cancel</button>
												<button type="submit" disabled={(!this.state.BUTTONS)} className="bg-primary w-32 text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm" >
													{(this.state.profile_spinner) ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Save Changes' }
												</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
								}
							</div>
							{
								(this.props.location.pathname.toLowerCase() === '/userprofile') &&
								<div className='user-body-card mt-12 px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden shadow-lg bg-white'>
                                <div className="flex pt-4 pb-2">
                                    <span className="float-left text-primary text-sm">Password Reset</span>
                                </div>

                                <form noValidate autoComplete="off" onSubmit={this.setNewPassword} >
                                    <div className="py-2">
                                        <Grid container spacing={2}>
                                            {/* <Grid item xs={12} sm={12} md={12}>
                                                <div className="flex items-center border-solid border border-gray-500 rounded">
                                                    <IconButton disabled={true} className="px-4 my-6" aria-label="phone">
                                                        <img src={lock}/>
                                                    </IconButton>
                                                    <InputBase
                                                        className="flex"
                                                        type="password"
                                                        placeholder="Old password"
                                                        // value={this.state.phone}
                                                        onChange={(event) => this.inputHandler(event, "old_password")}
                                                        inputProps={{ 'aria-label': 'old password' }}
                                                    />
                                                </div>
                                            </Grid> */}
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className="flex">
                                                    <div className="flex-initial w-1/2 text-center pr-4">
                                                        <div className="flex items-center border-solid border border-gray-500 rounded">
                                                            <IconButton disabled={true} className="px-4 my-6" aria-label="new_password">
                                                                <img src={lock}/>
                                                            </IconButton>
                                                            <InputBase
                                                                className="flex"
                                                                type="password"
                                                                placeholder="New password"
                                                                value={this.state.new_password}
                                                                onChange={(event) => this.inputHandler(event, "new_password")}
                                                                inputProps={{ 'aria-label': 'new password' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="flex-initial w-1/2 text-center">
                                                        <div className="flex items-center border-solid border border-gray-500 rounded">
                                                            <IconButton disabled={true} className="px-4 my-6" aria-label="confirm_password">
                                                                <img src={lock}/>
                                                            </IconButton>
                                                            <InputBase
                                                                className="flex"
                                                                type="password"
                                                                placeholder="Confirm Password"
                                                                value={this.state.confirm_password}
                                                                onChange={(event) => this.inputHandler(event, "confirm_password")}
                                                                inputProps={{ 'aria-label': 'confirm password' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
								
                                    <div className="flex my-2">
                                        <div className="flex-1">
                                        </div>
                                        <div className="flex-grow sm:flex-1">
                                            <div className="float-none sm:float-right">
                                                <button type="button" disabled={(!this.state.BUTTONS)} onClick={() => { this.setState({new_password: '', confirm_password: ''}); }} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Cancel</button>
												<button type="submit" disabled={(!this.state.BUTTONS)} className="bg-primary w-40 text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm" >
													{(this.state.password_spinner) ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Change Password' }
												</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
							</div>
							}
						</div>
					</div>
				</div>
			);
		// }
		// else {
		// 	return (<div />);
		// }
	}
}

UserProfile.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		CustomerDetail: (user_name, first_name, last_name, credit, address, city, state, zip) => {
			var value = { user_name, first_name, last_name, credit, address, city, state, zip }
			dispatch({ type: 'CustomerDetail', value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserProfile));
