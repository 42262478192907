const InitialState = {
    token: '',
    user_id: '',
    user_name: '',
    first_name: '',
    last_name: '',
    credit_balance: '',
    admintoken: '',
    snackbarMsg: '',
    user_type: '',
    admin_first_name: '',
    admin_last_name: '',
}

let STATE = InitialState
if (localStorage.getItem('SAFE_TECH') === null) {
    localStorage.setItem('SAFE_TECH', JSON.stringify(InitialState))
}
else {
    STATE = JSON.parse(localStorage.getItem('SAFE_TECH'))
}

const safe_tech_reducer = (state = STATE, action) => {
    switch (action.type) {
        case 'AdminLoginSuccess': {
            // console.log("Reducer AdminLoginSuccess=>>", action)
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                admin_first_name: action.value.first_name,
                admin_last_name: action.value.last_name,
            }))
            return {
                ...state,
                admin_first_name: action.value.first_name,
                admin_last_name: action.value.last_name,
            }
        }

        case 'CustomerLoginSuccess': {
            // console.log("Reducer CustomerLoginSuccess=>>", action)
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                token: action.value.token,
                user_id: action.value.user_id,
                user_type: action.value.user_type
            }))
            return {
                ...state,
                token: action.value.token,
                user_id: action.value.user_id,
                user_type: action.value.user_type
            }
        }

        case 'CustomerLogout': {
            //console.log("Reducer Logout=>>")
            localStorage.removeItem('SAFE_TECH')
            return {
                token: '',
                user_id: '',
                user_name: '',
                first_name: '',
                last_name: '',
                admin_first_name: '',
                admin_last_name: '',
                credit_balance: '',
                admintoken: '',
                snackbarMsg: '',
                user_type: '',
                address: '',
                city: '',
                state: '',
                zip: ''
            }
        }

        case 'UpdateCustomer': {
            // console.log("Reducer Login UpdateCustomer=>>" + action.value.user_id)
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                user_id: action.value.user_id,
                user_name: action.value.user_name,
                first_name: action.value.first_name,
                last_name: action.value.last_name,
                credit_balance: action.value.credit_balance,
                address: action.value.address,
                city: action.value.city,
                state: action.value.state,
                zip: action.value.zip
            }))
            return {
                ...state,
                user_id: action.value.user_id,
                user_name: action.value.user_name,
                first_name: action.value.first_name,
                last_name: action.value.last_name,
                credit_balance: action.value.credit_balance,
                address: action.value.address,
                city: action.value.city,
                state: action.value.state,
                zip: action.value.zip
            }
        }

        case 'CustomerDetail': {
            // console.log("Reducer CustomerDetail=>>", action)
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                user_name: action.value.user_name,
                first_name: action.value.first_name,
                last_name: action.value.last_name,
                credit_balance: action.value.credit,
                address: action.value.address,
                city: action.value.city,
                state: action.value.state,
                zip: action.value.zip
            }))
            return {
                ...state,
                user_name: action.value.user_name,
                first_name: action.value.first_name,
                last_name: action.value.last_name,
                credit_balance: action.value.credit,
                address: action.value.address,
                city: action.value.city,
                state: action.value.state,
                zip: action.value.zip
            }
        }

        case 'SaveAdminToken': {
            // console.log("Reducer Admin Token=>>")
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                admintoken: action.value
            }))
            return {
                ...state,
                admintoken: action.value
            }
        }

        case 'RemoveAdminToken': {
            // console.log("Reducer Remove Admin Token=>>")
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                admintoken: ''
            }))
            return {
                ...state,
                admintoken: ''
            }
        }

        case 'UpdateCredit': {
            // console.log("Reducer Update Credit=>>")
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                credit_balance: action.value
            }))
            return {
                ...state,
                credit_balance: action.value
            }
        }

        case 'UpdateSnackBarMsg': {
            //console.log("Reducer Snack MSG=>>" + action.value)
            localStorage.setItem('SAFE_TECH', JSON.stringify({
                ...state,
                snackbarMsg: action.value

            }))
            return {
                ...state,
                snackbarMsg: action.value
            }
        }
        default: return state
    }
}
export default safe_tech_reducer