import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import URLHelper from '../Helper/URLHelper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Header from './Header';
import UserHeader from './UserHeader';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	Margin: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	bootstrapInput: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		width: 'auto',
		padding: '10px 12px',
		margin: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#673ab7',
			boxShadow: '0 0 0 0.0rem #673ab7',
		},
	},
});

class ChoosePlan extends Component {
	constructor(props) {
		super(props)
		this.state = {
			plans: [],
			type: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: false,
			plan_id: '',
			plan_price: '',
			credit_balance: ''
		}
	}

	componentDidMount() {
		// console.log("Choose Plan Mount")
		// console.log(this.props)
		if (this.props.state.token === '') {
			window.location.href = '/'
		} else if (this.props.location.state !== undefined) {
			this.setState({ type: this.props.location.state.type, credit_balance: this.props.state.credit_balance })
			this.fetchPlanListing()
			this.fetchCurrentUserDetails(this.props.state.user_id)
		}
		else {
			window.location.href = '/MainMenu'
		}
	}

	componentDidUpdate(prevProps) {
		// console.log("previous credit_balance ::", prevProps.state.credit_balance)
		// console.log("new credit_balance ::", this.state.credit_balance)
		if (prevProps.state.credit_balance !== this.state.credit_balance) {
			// console.log("changed previous credit balance..")
			this.props.UpdateCustomer(this.props.state.user_id, this.props.state.first_name, this.state.credit_balance)
		}
	}

	async fetchCurrentUserDetails(accountid) {
		if (navigator.onLine) {
			this.setState({ BUTTONS: false })
			return fetch(URLHelper.GetUserDetail + "/" + accountid, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ BUTTONS: true })
					if (res.status) {
						var userData = res.data[0];
						// console.log('result user details : ', userData.user_id, userData.first_name, userData.credit_balance)
						this.setState({ credit_balance: userData.credit_balance })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', button: '' })
					this.setState({ snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						let PLANS = this.state.plans
						for (var i = 0; i < res.data.length; i++) {
							PLANS.push({ plan_id: res.data[i].plan_id, price: res.data[i].price, bonus: res.data[i].bonus })
						}
						this.setState({ plans: PLANS })
					}
					else {
						this.setState({ spinner: 'none', snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
					.catch((error) => {
						this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
					})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	PaymentMethod = (plan) => {
		var plan = {
			type: this.props.location.state.type,
			plan_id: plan.plan_id,
			plan_price: plan.price,
		}
		this.props.history.push("/PaymentMethod", { PropsData: plan })
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	render() {
		const { classes } = this.props;
		if (this.props.state.token !== '') {
			return (
				<div className="App">
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar>
					{/* {this.state.BUTTONS && <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
						{
							this.props.Logout()
							window.location.href = "/"
						}
					}}>Sign Out</Button>} */}

					{/* <Header propsState={this.props.state} /> */}
					<UserHeader propsState={this.props.state} />

					<Grid container>
						<Grid item xs />
						<Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
							<div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '30px 20px', marginBottom: '5%' }}>
								<Grid container>
									<Grid item xs={6}>
										<p style={{ color: '#000000', fontSize: 16, fontWeight: 'bold' }}>Choose Plan</p>
									</Grid>
									<Grid item xs={6} style={{ textAlign: 'end' }}>
										<p style={{ color: '#000000', float: 'right', fontSize: 16, fontWeight: 'bold' }}>Credit $ {this.state.credit_balance}</p>
									</Grid>
								</Grid>
								<Grid container>
									{this.state.plans.length > 0 &&
										this.state.plans.map((plan, index) =>
											<Grid item xs={6} style={{ padding: 5 }} key={index}>
												<Button variant="contained" onClick={() => this.PaymentMethod(plan)} style={{ fontWeight: 'bold', width: '100%', height: 150, backgroundColor: '#32cd32', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
													<div style={{ textAlign: 'center', verticalAlign: 'center' }}>
														<h1>${plan.price}</h1>
														<p style={{ marginTop: -20, fontSize: 20 }}> {((plan.price + plan.bonus) / 0.15).toFixed(0)} Minutes</p>
													</div>
												</Button>
											</Grid>
										)
									}
									<Button variant="contained" onClick={() => this.props.history.goBack()} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
										Back
                      				</Button>
								</Grid>
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress className={classes.progress} /></center>
								</div>
							</div>
						</Grid>
						<Grid item xs />

					</Grid>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

ChoosePlan.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit) => {
			var value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChoosePlan));
