import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Header from './Header';
import logo from '../assets/logo-alt.png';
import square_rounded from '../assets/square_rounded.png';
import pc_mouse from '../assets/pc_mouse.png';
import nextPng from '../assets/next.png';
import progressPng from '../assets/progress.png';
import contact_bg from '../assets/contact_bg.png';
import TextField from '@material-ui/core/TextField';
import SafeTechSnackbar from './SafeTechSnackbar';
import clsx from 'clsx';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	mainHeader: {
        height: '600px',
        opacity: 1
    },
	subHeader: {
        height: '600px',
        background: '#D3CEDE 0% 0% no-repeat padding-box;',
        opacity: 1
    },
});

class ContactUs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			path: '',
			spinner: 'none',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			BUTTONS: true,
			name: '',
			email: '',
			subject: '',
			yourMsg: '',
		}
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	componentWillMount() {
		// console.log('on contactus state : ', this.props);
		const pathName = this.props.location.pathname.toLowerCase()
		if (this.props.state.snackbarMsg != '') {
			this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true, snackVariant: 'error', show : true })
			this.props.UpdateMSG('')
		}

		if (pathName === "/contactus") {
			this.setState({ path: pathName });
			// console.log('where pathName is /contactus...')
			if (this.props.state.token !== '' && this.props.state.user_type === 'Admin') {
				// console.log('token is not blank and user type is admin...')
				window.location.href = "/AccountList";
			}else if(this.props.state.token !== '' && this.props.state.user_type === 'User'){
				// console.log('token is not blank and user type is user...')
				this.setState({ show : true });
			}else{
				// console.log('didn\'t fall in any condition...')
				this.setState({ show : false });
				window.location.href = "/";
			}
		} else if (this.props.match.params.userName !== undefined || this.props.match.params.adminToken !== undefined) {
			// console.log('where both are not undefined...')
			if (this.props.state.token !== '' && this.props.state.user_type === 'Admin') {
				// console.log('external url token is not blank and user type is admin...')
				window.location.href = "/AccountList";
			}else{
				// console.log('external url token is blank and user type is not admin...')
				this.fetchUserId(this.props.match.params.userName);
			}
			// window.location.href = "/";
		} else {
			// console.log('the lats part....')
			window.location.href = "/";
		}
	}

	componentWillUnmount(){
		this.setState({
			show: false,
			path: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: true,
			subject: '',
			yourMsg: ''
		});
	}

	FormSubmit = (event) => {
		event.preventDefault();
		if (this.state.name === '') {
			this.setState({ snackMsg: "Name is Required.", snack: true, snackVariant: 'error' })
		} else if (this.state.email === '') {
			this.setState({ snackMsg: "Email is Required.", snack: true, snackVariant: 'error' })
		} else if (this.state.subject === '') {
			this.setState({ snackMsg: "Subject is Required.", snack: true, snackVariant: 'error' })
		} else if (this.state.yourMsg === '') {
			this.setState({ snackMsg: "Message is Required.", snack: true, snackVariant: 'error' })
		}
		else {
			this.setContactToSystem();
		}
	}

	async fetchUserId(userName) {
        if (navigator.onLine) {
            return fetch(URLHelper.GetUserId + "/" + userName, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.match.params.adminToken
                },
            }).then((Response) => Response.json())
                .then((res) => {
                    if (res.status) {
						let userData = res.data[0];
						console.log('got user id with other details :->',userData)
						if(userData.role === "User"){
							console.log('user role is user...')
							this.setState({ show: true });
						}else{
							console.log('user role is not user...')
							window.location.href = "/";
						}
                    }
                    else {
                        console.log('Something went wrong at contact us...')
                        window.location.href = "/";
                    }
                })
                .catch((error) => {
                    // console.log("Error==>" + error)
                    this.setState({ spinner: 'none', snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error' })
                    console.log('Something went wrong at contact us...')
                    window.location.href = "/";
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
            console.log('Internet Not Available. Try Again!!')
            window.location.href = "/";
        }
    }

	async setContactToSystem() {
		if (navigator.onLine) {
			console.log('setContactToSystem :', this.props.state.user_name)
			let token = (this.props.match.params.adminToken !== undefined) ? this.props.match.params.adminToken : this.props.state.token;
			// let user_name = (this.props.match.params.userName !== undefined) ? this.props.match.params.userName : this.props.state.user_name;
			let Data = {
				user_name: (this.state.path === '/contactus') ? this.props.state.user_name : this.props.match.params.userName,
				name: this.state.name,
				email: this.state.email,
				subject: this.state.subject,
				message: this.state.yourMsg
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.newContactUs, {
			// return fetch((this.state.path === '/contactus') ? URLHelper.contactUs : URLHelper.newContactUs, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log(res)
						this.setState({name: '', email: '', subject: '', yourMsg: '', spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'success', BUTTONS: true })
						// setTimeout(() => this.props.history.push("/MainMenu"), 5000)
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	hideSnackBar = () => {
		this.setState({ snack: false })
	}

	render() {
		const { classes } = this.props;
		// console.log('#Token and User id#', this.props.state.token, this.props.state.user_name)
		// console.log('userName :=>',this.props.match.params.userName , 'adminToken :=>', this.props.match.params.adminToken)
		// console.log('this.state.show :=>',this.state.show)
		if (this.state.show === true) {
		return (
			<div className={clsx('h-screen')}>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.snack}
					autoHideDuration={5000}
					onClose={() => this.setState({ snack: false })}>
					<SnackbarContent
						className="error"
						aria-describedby="client-snackbar"
						message={this.state.snackMsg}
					/>
				</Snackbar>
				<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
				{/* <Header propsState={this.props.state} /> */}
				{/* <div className="grid grid-rows-3 grid-flow-col gap-4 bg-gray-500">
					<div className="row-span-1 col-span-4 bg-gray-200"> sdsd</div>
					<div className="row-span-2 col-span-4 bg-gray-200"> sdssdfdf cxcxcs</div>
					<div className="row-span-3 bg-gray-300 -mt-40 -mr-64">
						<img src={square_rounded} style={{ height: '900px' }} className="float-right" alt="square_rounded"/>
						<img src={pc_mouse} style={{ height: '400px' }} className="relative float-right mt-20p -mr-35p" alt="pc-mouse"/>
					</div>
				</div> */}
				<div className={clsx(classes.mainHeader, 'bg-gray-400')}>
					<div className={clsx(classes.subHeader, 'z-50')} style={{ backgroundImage: `url(${contact_bg})`, backgroundPosition: 'center', backgroundSize: '125%' }}>
					{/* <div className={clsx(classes.subHeader)} style={{ backgroundImage: `url(${contact_bg})`, backgroundSize: 'cover' }}> */}
						
						<nav className="flex items-center justify-between flex-wrap p-6">
							<div className="flex items-center flex-shrink-0 text-white mr-6">
								<img src={logo} className="inline h-20 ml-0 md:ml-6 my-6" alt="qisqam-logo"/>
							</div>
							<div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
								<div className="text-sm flex-none lg:flex-grow">
									<a href="#" className="ml-0 md:ml-40 mt-4 lg:mt-0 text-primary mr-6">Features</a>
									<a href="#" className="mt-4 lg:mt-0 text-primary mr-6">How it works</a>
									<a href="#" className="mt-4 lg:mt-0 text-primary mr-6">Contact</a>
									{/* <button onClick={() => this.props.history.push("/MainMenu")} type="button" className="ml-0 md:ml-40 mt-4 lg:mt-0 text-primary mr-6">
										Features
									</button>
									<button onClick={() => this.props.history.push("/UserProfile")} type="button" className="mt-4 lg:mt-0 text-primary mr-6">
										How it works
									</button>
									<button onClick={() => this.props.history.push("/UserProfile")} type="button" className="mt-4 lg:mt-0 text-primary mr-6">
										Contact
									</button>*/}
									<button onClick={() => this.props.history.push("/")} type="button" className="font-hairline text-white text-base font-sans float-left md:float-right ml-0 md:ml-64 mt-4 lg:mt-0 text-white mr-4">
										Login
									</button> 
								</div>
								<div>
									<button type="button"
									onClick={() => this.props.history.push("/AdminApproval")}
									className="text-sm px-6 py-2 mr-0 sm:mr-12 font-medium text-xl
									transition ease-in duration-200
									leading-normal border rounded border-transparent text-secondary bg-white hover:text-white hover:bg-secondary">
									Sign up
									</button>
								</div>
							</div>
						</nav>
						<div className="flex flex-row ">
							<div className="flex-col ml-28 mt-8">
								<div><h1 className="text-primary font-sans-roboto font-extrabold" style={{ fontSize: '45px' }}>Convenient Filtered Internet</h1></div>
								<div className="max-w-lg pt-2 font-sans-roboto text-bodyText text-xl opacity-50"><p>Browse the web, print document or just use the computer for whatever you need at any of our 2 convenient and comfortable locations.</p></div>
								<div className="pt-4">
									<div className="flex">
										<input className="pl-6 w-64 rounded" type="email" placeholder="Email" name="email"></input>
										<button className="ml-2 w-52 shadow-lg bg-primary text-white p-2 rounded" type="button">Get started Now <img src={nextPng} className="inline h-3 pl-3" alt="next-png"/></button>
									</div>
								</div>
							</div>
							<div className="flex-grow">
								<img src={pc_mouse} className="ml-30p" style={{ height: '400px' }} alt="pc-mouse"/>
							</div>
						</div>
					</div>
				</div>
				<h1 className="text-center mt-6 font-sans-roboto text-5xl">Contact Us</h1>
				<Grid container className="pb-6">
					<Grid item xs />
					<Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
						<div container className="p-4" style={{ marginTop: '9.44%', border: '0.5px solid #707070' }}>
							<form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
								{/* <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Subject</p> */}
								<div className="w-full flex mb-2">
									<div className="flex-1 mx-1">
										<TextField
											autoFocus={true}
											id="name"
											label="Name"
											className="w-full"
											value={this.state.name}
											onChange={(event) => this.setState({ name: event.target.value })}
											variant="outlined"
										/>
									</div>
									<div className="flex-1 mx-1">
										<TextField
											id="email"
											label="Email"
											className="w-full"
											value={this.state.email}
											onChange={(event) => this.setState({ email: event.target.value })}
											variant="outlined"
										/>
									</div>
								</div>
								<TextField
									id="subject"
									label="Subject"
									className={classes.Margin}
									margin="normal"
									style={{ width: '100%' }}
									value={this.state.subject}
									onChange={(event) => this.setState({ subject: event.target.value })}
									variant="outlined"
								/>
								{/* <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Your Message</p> */}
								<TextField
									autoFocus={false}
									id="yourmsg"
									label="Your Message"
									className={classes.Margin}
									margin="normal"
									style={{ width: '100%' }}
									multiline
									rows="10"
									value={this.state.yourMsg}
									onChange={(event) => this.setState({ yourMsg: event.target.value })}
									variant="outlined"
								/>
								{this.state.BUTTONS &&
									<Grid container>
										<button
                                            type="submit"
                                            disabled={(this.state.spinner === 'block')}
                                            className="bg-primary w-full text-white py-4 shadow-xl rounded font-sans text-xs md:text-sm">
                                                {(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Send' }
                                        </button>

										{/* <Button type="submit" variant="contained"  style={{ textTransform: 'none', fontWeight: 'bold', width: '20%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
											Send
                                        </Button> */}
									</Grid>}
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress className={classes.progress} /></center>
								</div>
							</form>
						</div>
					</Grid>
					<Grid item xs />

				</Grid>
			</div>
		);
		}
		else {
			return (<div />);
		}
	}
}

ContactUs.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ContactUs));

