import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import AppHelper from '../Helper/AppHelper';
import Header from './Header';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '0px 10px',
        //margin: '10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class AdminApprovalPayment extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            type: '',
            AdminToken: '',
            snackMsg: '',
            snack: false,
            spinner: 'none',
            BUTTONS: true,
            open: false,
            PropsData: ''
        }
    }

    componentWillMount() {
        //console.log("Admin Approval Payment Mount")
        //console.log(this.props)
        this.setState({ type: this.props.match.params.type })
        if (this.props.state.token === '') {
            //console.log("AdminApproval No Token")
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.Data })
            if (this.state.PropsData.type == 'auto' && this.state.PropsData.plan_price != 0 && this.state.PropsData.plan_id == 0) {
                //console.log("AdminApproval Without State")
                window.location.href = "/MainMenu"
            }
        }
        else {
            //console.log("AdminApproval State")
            window.location.href = "/MainMenu"
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();
        if (this.state.password !== '') {
            this.VerifyAdmin()
        } else {
            this.setState({ snackMsg: "Password is Required.", snack: true })
        }
    }

    async VerifyAdmin() {
        if (navigator.onLine) {
            var Data = {
                password: this.state.password
            }
            //console.log(Data)
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.GetAdminToken, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none', BUTTONS: false })
                    if (res.status) {
                        //console.log("Hiii")
                        this.DoPayment(res.data)
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
                    }
                })
                .catch((error) => {
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    async DoPayment(token) {
        if (navigator.onLine) {
            var Data = {
                admin_token: token,
                creditcard_id: '0',
                cvv: '',
                user_id: this.props.state.user_id,
                plan_id: this.props.location.state.PropsData.plan_id,
                custom_amount: this.props.location.state.PropsData.plan_id == 0 ? this.props.location.state.PropsData.plan_price : 0,
                method: 'cash',
                transactiontype: 'refill',
                purchase_type: 'plan'
            }
            // console.log("DoPayment called..")
            // console.log(Data)
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.DoPayment, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    if (res.status) {
                        var credit;
						if(res.data.credit_balance != null && res.data.credit_balance != 'undefined'){
							//credit= (res.data.credit_balance / 0.15).toFixed(2)
							credit= res.data.credit_balance
						}
						else{
							credit = 0.00
						}
                        this.props.UpdateCredit(credit)
                        this.props.history.push("/RefillSuccess", { PropsData: credit, PurchaseType: Data.purchase_type, PurchaseData: res.data })
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
                    }
                })
                .catch((error) => {
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    PaymentCancel = () => {
        this.setState({ open: true })
    }

    render() {
        const { classes } = this.props;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{AppHelper.DailogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                                No
                        </Button>
                            <Button onClick={() => {
                                this.setState({ open: false })
                                this.props.history.goBack()
                            }} color="primary" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snack}
                        autoHideDuration={1500}
                        onClose={() => this.setState({ snack: false })}>
                        <SnackbarContent
                            className="error"
                            aria-describedby="client-snackbar"
                            message={this.state.snackMsg}
                        />
                    </Snackbar>
                    {/* {this.state.BUTTONS && <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button>} */}

                    <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                            <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '5px 20px 20px' }}>
                                <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                                    <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Admin Approval</p>

                                    <TextField
                                        autoFocus={true}
                                        id="password"
                                        label="Admin Password"
                                        className={classes.Margin}
                                        type="password"
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        value={this.state.password}
                                        onChange={(event) => this.setState({ password: event.target.value })}
                                        variant="outlined"
                                    />
                                    {this.state.BUTTONS &&
                                        <Grid container>
                                            <Button type="submit" variant="contained" style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                Continue
				                </Button>
                                            <Button variant="contained" onClick={() => this.PaymentCancel()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                Back
                                </Button>
                                        </Grid>}
                                    <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                        <center>
                                            <CircularProgress className={classes.progress} />
                                            <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitTitle}</p>
                                            <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitMsg}</p>
                                        </center>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                        <Grid item xs />

                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

AdminApprovalPayment.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        UpdateCredit: (credit) => dispatch({ type: 'UpdateCredit', value: credit })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminApprovalPayment));
