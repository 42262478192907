import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import AppHelper from '../Helper/AppHelper'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TextField from '@material-ui/core/TextField';
import Header from './Header';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        margin: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
});

class EnterExistCCInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agree: true,
            CVV: '',
            type: '',
            snack: false,
            snackMsg: '',
            spinner: 'none',
            BUTTONS: true,
            open: false,
            PropsData: '',
            signoutButtonDisabled: false
        }
    }

    componentWillMount() {
        // console.log("Exist CC Mount")
        // console.log(this.props)
        this.setState({ type: this.props.match.params.type })
        if (this.props.state.token === '') {
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.PropsData })
            if (this.state.PropsData.type == 'auto' && this.state.PropsData.plan_price != 0 && this.state.PropsData.plan_id == 0) {
                this.props.history.push("/MainMenu")
            }
        }
        else {
            window.location.href = "/MainMenu"
        }
    }

    inputHandler = (event, inputID) => {
        var value = event.target.value
        if (inputID == "CVV") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ CVV: value })
                }
            }
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();
        if (this.state.CVV !== '') {
            if (this.state.agree) {
                if (this.state.CVV.length > 2 && this.state.CVV.length < 5) {
                    this.DoPayment()
                }
                else {
                    this.setState({ snackMsg: "CVV is invalid.", snack: true })
                }
            }
            else {
                this.setState({ snackMsg: "Please accept our terms and conditions.", snack: true })
            }
        }
        else {
            this.setState({ snackMsg: "Please enter CVV.", snack: true })
        }
    }

    async DoPayment() {
        if (navigator.onLine) {
            var Data = {}
            Data.creditcard_id = this.state.PropsData.creditcard_id;
            Data.cvv = this.state.CVV;
            Data.user_id = this.props.state.user_id;
            (this.state.PropsData.type === 'item_refill') ? Data.item_id = this.props.location.state.PropsData.item_id : Data.plan_id = this.props.location.state.PropsData.plan_id;
            // Data.custom_amount = this.props.location.state.PropsData.plan_id == 0 ? this.props.location.state.PropsData.plan_price : 0;
            Data.custom_amount = this.props.location.state.PropsData.plan_id == 0 ? this.props.location.state.PropsData.plan_price : (this.props.location.state.PropsData.item_id < 0 ? this.props.location.state.PropsData.price : 0);
            Data.method = 'creditcard';
            Data.transactiontype = this.props.location.state.PropsData.type === 'item_refill' ? 'refill' : this.props.location.state.PropsData.type;
            Data.purchase_type = this.state.PropsData.type === 'item_refill' ? 'item' : 'plan';
            // console.log("Existing Card Payment")
            // console.log(Data)
            this.setState({ spinner: 'block', BUTTONS: false, signoutButtonDisabled: true })
            return fetch(URLHelper.DoPayment, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                Response.json().then((res) => {
                    this.setState({ spinner: 'none', BUTTONS: false, signoutButtonDisabled: true })
                    if (res.status) {
                        var credit;
                        if (res.data.credit_balance != null && res.data.credit_balance != 'undefined') {
                            //credit= (res.data.credit_balance / 0.15).toFixed(2)
                            credit = res.data.credit_balance
                        }
                        else {
                            credit = 0.00
                        }
                        this.props.UpdateCredit(credit)
                        // this.props.history.push("/RefillSuccess", { PropsData : credit})
                        this.props.history.push("/RefillSuccess", { PropsData: credit, PurchaseType: Data.purchase_type, PurchaseData: res.data })
                    }
                    else {
                        console.log('Refill failed for existing card.... ')
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true, signoutButtonDisabled: false })
                    }
                })
                    .catch((error) => {
                        this.setState({ spinner: 'none', BUTTONS: true, signoutButtonDisabled: false })
                        this.setState({ snackMsg: "Something went wrong.", snack: true })
                    })
            })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    PaymentCancel = () => {
        this.setState({ open: true })
    }

    render() {
        const { classes } = this.props;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{AppHelper.DailogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                                No
                        </Button>
                            <Button onClick={() => {
                                this.setState({ open: false })
                                this.props.history.goBack()
                            }} color="primary" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snack}
                        autoHideDuration={10000}
                        onClose={() => this.setState({ snack: false })}>
                        <SnackbarContent
                            className="error"
                            aria-describedby="client-snackbar"
                            message={this.state.snackMsg}
                        />
                    </Snackbar>
                    {/* <Button disabled={this.state.signoutButtonDisabled} style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button> */}

                    <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                            <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '10px 20px' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6} style={{ padding: 10 }}>
                                        <p style={{ color: '#000000', fontSize: 16, fontWeight: 'bold' }}>Card ending in {this.props.location.state.PropsData.last_four}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} style={{ padding: 10, textAlign: 'end' }}>
                                        <p style={{ color: '#000000', float: 'right', fontSize: 16, fontWeight: 'bold' }}>{this.state.PropsData.type === 'item_refill' ? 'Purcahse Item' : 'Refill'} ${this.state.PropsData.type === 'item_refill' ? this.state.PropsData.price : this.state.PropsData.plan_price}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <form className={classes.container} noValidate autoComplete="off" style={{ marginTop: 20, marginBottom: 50 }} onSubmit={this.FormSubmit}>
                                            <Grid item xs={12} sm={12} md={6}>
                                                <TextField
                                                    autoFocus={true}
                                                    type="tel"
                                                    id="cvv"
                                                    label="CVV"
                                                    value={this.state.CVV}
                                                    onChange={(event) => this.inputHandler(event, "CVV")}
                                                    className={classes.Margin}
                                                    variant="outlined"
                                                    margin="normal"
                                                    style={{ width: '100%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} style={{ padding: 10 }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.agree}
                                                            onChange={() => this.setState({ agree: !this.state.agree })}
                                                            value={this.state.agree}
                                                            style={{
                                                                color: '#009688'
                                                            }}
                                                        />
                                                    } style={{ color: '#000000' }}
                                                    label="Agree to terms and conditions"
                                                />
                                            </Grid>
                                            {this.state.BUTTONS &&
                                                <Grid container style={{ padding: 10 }}>
                                                    <Button type="submit" variant="contained" className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#32CD32', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                        Process Payment
                                            </Button>
                                                    <Button variant="contained" onClick={() => this.PaymentCancel()} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                        Back
                                            </Button>
                                                </Grid>}
                                            <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                                <center>
                                                    <CircularProgress className={classes.progress} />
                                                    <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitTitle}</p>
                                                    <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitMsg}</p>
                                                </center>
                                            </div>
                                        </form>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs />

                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

EnterExistCCInfo.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        UpdateCredit: (credit) => dispatch({ type: 'UpdateCredit', value: credit })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnterExistCCInfo));
