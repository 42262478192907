// const base_url = "http://localhost:3000/"
const base_url = "http://139.59.41.77:2222/"

const URLHelper = {
    AddUser: base_url + "addUser",
    Login: base_url + "userLogin",
    ForgotPassword: base_url + "forgotPassword",
    SetNewPassword: base_url + "changePassword",
    SetNewBalance: base_url + "setNewBalance",
    SetTempPassword: base_url + "changeAdminPassword",
    AutoLogin: base_url + "autoLogin",
    GetUserDetail: base_url + "getUserById",
    GetUserId: base_url + "getUserByUserName",
    GetPlans: base_url + "getPlans",
    GetItems: base_url + "getItems",
    GetUsers: base_url + "getUsers",
    GetSubscriptionPlans: base_url + "getSubscriptionPlans",
    GetGroupPolicies: base_url + "getGroupPolicies",
    GetUsageReport: base_url + "getUsageReports",
    GetSalesReport: base_url + "getSalesReports",
    GetCardListing: base_url + "getCreditCard",
    AddCreditCard: base_url + "addCreditCard",
    SignUp: base_url + "addUser",
    EditUser: base_url + "editUser",
    AddNewAccount: base_url + "addNewAccount",
    AddUserSubscriptionPlan: base_url + "addUserSubscriptionPlan",
    DoPayment: base_url + "addTransaction",
    GetAdminToken: base_url + "getAdminToken",
    ConfirmAutoReplenish: base_url + "addAutoReplenish",
    ToggleAutoReplenish: base_url + "toggleAutoReplenish",
    GetUserLogs: base_url + "userLogs",
    GetUserSubscribedPlans: base_url + "getSubscriptionPlanByUserId",
    GetUserBilling: base_url + "getTransactionById",
    RemoveCreditCard: base_url + "removeCreditCard",
    SubscriptionPlanActions: base_url + "subscriptionPlanActions",
    SetDefaultCreditCard: base_url + "setDefaultCreditCard",
    contactUs: base_url + "contactUs",
    newContactUs: base_url + "newContactUs",
    // GetXToken: "https://cdn.cardknox.com/api/ifields/gettoken"
    SalesReportUrl: "https://datastudio.google.com/embed/reporting/",
    UsageReportUrl: "https://datastudio.google.com/embed/reporting/"
};

export default URLHelper;