import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '0px 10px',
        //margin: '10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
});

class AdminApproval extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            type: '',
            AdminToken: '',
            snackMsg: '',
            snack: false,
            spinner: 'none',
            BUTTONS: true
        }
    }

    componentWillMount() {
        //console.log("Admin Approval Mount")
    }

    FormSubmit = (event) => {
        event.preventDefault();
        if (this.state.password !== '') {
            this.VerifyAdmin()
        } else {
            this.setState({ snackMsg: "Password is Required.", snack: true })
        }
    }

    async VerifyAdmin() {
        if (navigator.onLine) {
            var Data = {
                password: this.state.password
            }
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.GetAdminToken, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    //console.log(res)
                    if (res.status) {
                        this.props.SaveAdminToken(res.data)
                        this.props.history.push("/SignUp",{ PropsData: res.status })
                        //window.location.href="/SignUp"
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
                    }
                })
                .catch((error) => {
                    //console.log("Error==>" + error)
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.snack}
                    autoHideDuration={1500}
                    onClose={() => this.setState({ snack: false })}>
                    <SnackbarContent
                        className="error"
                        aria-describedby="client-snackbar"
                        message={this.state.snackMsg}
                    />
                </Snackbar>
                <Grid container>
                    <Grid item xs />
                    <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                        <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '30px 20px' }}>
                            <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                                <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Admin Approval</p>
                                <TextField
                                    autoFocus={true}
                                    id="password"
                                    label="Admin Password"
                                    className={classes.Margin}
                                    type="password"
                                    margin="normal"
                                    style={{ width: '100%' }}
                                    value={this.state.password}
                                    onChange={(event) => this.setState({ password: event.target.value })}
                                    variant="outlined"
                                />
                                {this.state.BUTTONS &&
                                    <Grid container>
                                        <Button type="submit" variant="contained" style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Continue
                                        </Button>
                                        <Button variant="contained" onClick={() => this.props.history.goBack()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Back
                                        </Button>
                                    </Grid>}
                                <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                    <center><CircularProgress className={classes.progress} /></center>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs />

                </Grid>
            </div>
        );
    }
}

AdminApproval.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        SaveAdminToken: (token) => dispatch({ type: 'SaveAdminToken', value: token })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AdminApproval));
