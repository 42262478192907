import './css/App.css';
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import '@y0c/react-datepicker/assets/styles/calendar.scss';
// import Header from './Header';
import AdminHeader from './AdminHeader';
import URLHelper from '../Helper/URLHelper';

class UsageReportIframe extends Component {

	constructor(props) {
		super(props)
		this.state = {
			spinner: 'none',
			snackMsg: '',
			snack: false,
			Page: 1,
			chartData: [],
			salesList: [],
		}
	}

	componentDidMount() {
		if (this.props.state.token === '' || this.props.state.user_type === 'User') {
			window.location.href = "/"
		}
	}

	render() {
		return (
			<div className="App">
				<React.Fragment>

					{/* <Header propsState={this.props.state} /> */}
					<AdminHeader propsState={this.props.state} />

					{/* <Grid container>
						<Grid item xs={2} sm={2} md={2} >
						</Grid>
						<Grid item xs={10} sm={10} md={10} >
							<p style={{ color: '#000000', fontSize: 28, float: 'left' }}>Usage Report</p>
						</Grid>
						<Grid item xs={2} sm={2} md={2} >
						</Grid>
					</Grid> */}

					<Grid container style={{ justifyContent: 'center' }}>

						<Grid item xs={12}>

							<iframe
								src={URLHelper.UsageReportUrl}
								width="100%"
								height="650"
								onLoad={this.hideSpinner}
								frameBorder="0"
								marginHeight="0"
								marginWidth="0"
								allowFullScreen
							/>

						</Grid>

					</Grid>

				</React.Fragment>
			</div>
		);
	}
}


const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UsageReportIframe);