import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import logo from '../assets/logo-alt.png';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles(theme => ({

    menuItem: {
        color: '#673ab7',
        fontSize: '16px'
    },
    root: {
        display: 'flex',
    },
    userHeader: {
        // height: '350px',
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    },
    headerRow: {
        background: 'transparent radial-gradient(closest-side at 50% 50%, #FFFFFFA8 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
        opacity: 0.2
    },
    userPorfile: {
        background: '#273359 0% 0% no-repeat padding-box',
        boxShadow: '0px 5px 25px #00000029',
        border: '5px solid #273359',
        opacity: 1,
    },
    activeNav: {
        borderBottom: '4px solid #F1B64C',
        color: '#F1B64C'
    }
}));

function UserHeader(props) {
    // console.log('UserHeader component :', props)
    const pathName = props.location.pathname.toLowerCase()
    const classes = useStyles();
    const { credit_balance } = props.propsState
    const [isOpen, setIsOpen] = React.useState(false);
    const dispatch = useDispatch();

    function makeUserName(first_name, last_name) {
        // console.log('called makeUserName first_name : ', first_name)
        // console.log('called makeUserName last_name : ', last_name)
        let firstChar = first_name.charAt(0).toUpperCase();
        let secondChar = last_name.charAt(0).toUpperCase();
        return firstChar+secondChar;
    }

    return (
        <>
        <header className={clsx(classes.userHeader, "pb-32 sm:flex sm:justify-between sm:items-center sm:px-4 sm:pb-40")}>
            <div className="flex items-center justify-between px-4 py-4 sm:p-0">
                <div className="flex items-center sm:flex-none sm:justify-between">
                    <img src={logo} className="inline h-16 sm:h-20 ml-0 md:ml-6 my-6" alt="qisqam-logo"/>
                    <span className="block sm:hidden text-white text-lg tracking-normal ml-4 font-thin">Credit ${ credit_balance }</span>
                    <div className="ml-2 relative hover-trigger block sm:hidden">
                        <button className={clsx(classes.userPorfile, 'p-1 text-white hover:border-secondary hover:bg-secondary text-2xl rounded-full transition ease-in duration-200')}>
                            {makeUserName(props.propsState.first_name,props.propsState.last_name)}
                        </button>
                        <div className="absolute shadow-2xl transition ease-in duration-200 focus:outline-none px-4 py-4 hover-target font-sans">
                            My Account
                            <button
                            onClick={() =>{
                                dispatch({ type: 'CustomerLogout' })
                                window.location.href = "/"
                            }}
                            className="my-2 px-3 rounded text-white transition duration-500 ease-in-out bg-safeRed transform hover:-translate-y-1 hover:scale-100 ...">
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
                <div className="sm:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} type="button" className="block text-gray-500 hover:text-white focus:text-white focus:outline-none">
                        <MenuIcon/>
                    </button>
                </div>
            </div>
            <nav className={clsx(isOpen ? 'block' : 'hidden', "px-2 pt-2 pb-4 sm:flex sm:p-0")}>
                <div className="text-white text-sm font-sans -ml-0 sm:-ml-52">
                    <button onClick={() => props.history.push("/MainMenu")} type="button" className={clsx( pathName === "/mainmenu" && classes.activeNav, "ml-0 md:ml-64 mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
                        Dashboard
                    </button>
                    <button onClick={() => props.history.push("/UserProfile")} type="button" className={clsx( pathName === "/userprofile" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
                        Profile
                    </button>
                    <button onClick={() => props.history.push("/Billing")} type="button" className={clsx( pathName === "/billing" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
                        Billing
                    </button>
                    <button onClick={() => props.history.push("/UserHistory")} type="button" className={clsx( pathName === "/userhistory" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
                        History
                    </button>
                    <button onClick={() => props.history.push("/Plans", {PropsData: { type: 'refill' } })} type="button" className={clsx( pathName === "/plans" && classes.activeNav, "mt-4 border-bottom-2 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
                        Add Time 
                    </button>
                </div>
            </nav>
            <div className="mr-8 hidden sm:block">
                <div className="flex items-center">
                    <span className="text-white text-lg tracking-normal mr-6 font-thin">Credit ${ credit_balance }</span>
                    <div className="relative hover-trigger">
                    <button className={clsx(classes.userPorfile, 'p-2 text-white hover:border-secondary hover:bg-secondary text-2xl rounded-full transition ease-in duration-200')}>
                        {makeUserName(props.propsState.first_name,props.propsState.last_name)}
                    </button>
                    <div className="absolute shadow-2xl transition ease-in duration-200 focus:outline-none px-4 py-4 hover-target font-sans">
                        My Account
                        <button
                        onClick={() =>{
                            dispatch({ type: 'CustomerLogout' })
                            window.location.href = "/"
                        }}
                        className="my-2 px-3 rounded text-white transition duration-500 ease-in-out bg-safeRed transform hover:-translate-y-1 hover:scale-100 ...">
                            Logout
                        </button>
                    </div>
                </div>
                </div>
            </div>
        </header>
        <hr className={clsx(classes.headerRow, 'w-4/5 mx-auto border border-gray-200 -mt-32')} />
        </>


        // <div className={clsx(classes.userHeader, 'w-full bg-left-top bg-auto bg-repeat-x')}>
        //     <nav className="flex items-center justify-between flex-wrap p-6">
        //         <div className="flex items-center flex-shrink-0 text-white mr-6">
        //             <img src={logo} className="inline h-20 ml-0 md:ml-6 my-6" alt="qisqam-logo"/>
        //         </div>
        //         <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        //             <div className="text-sm lg:flex-grow mt-12">
        //                 <div className="w-full">
        //                     <button onClick={() => props.history.push("/MainMenu")} type="button" className={clsx( pathName === "/mainmenu" && classes.activeNav, "ml-0 md:ml-64 mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
        //                         Dashboard
        //                     </button>
        //                     <button onClick={() => props.history.push("/UserProfile")} type="button" className={clsx( pathName === "/userprofile" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
        //                         Profile
        //                     </button>
        //                     <button onClick={() => props.history.push("/Billing")} type="button" className={clsx( pathName === "/billing" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
        //                         Billing
        //                     </button>
        //                     <button onClick={() => props.history.push("/UserHistory")} type="button" className={clsx( pathName === "/userhistory" && classes.activeNav, "mt-4 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
        //                         History
        //                     </button>
        //                     <button onClick={() => props.history.push("/Plans", {PropsData: { type: 'refill' } })} type="button" className={clsx( pathName === "/plans" && classes.activeNav, "mt-4 border-bottom-2 lg:mt-0 text-white hover:text-safeYellow mr-4")}>
        //                         Add Time 
        //                     </button>
        //                     <span className="text-white text-lg tracking-normal mr-6 font-thin float-right">Credit ${ credit_balance }</span>
        //                 </div>
        //             </div>
        //             <div className="mt-12 mr-24">
        //                 <div className="relative hover-trigger">
        //                     <button className={clsx(classes.userPorfile, 'p-2 text-white hover:border-secondary hover:bg-secondary text-2xl rounded-full transition ease-in duration-200 focus:outline-none')}>
        //                         {makeUserName(props.propsState.first_name,props.propsState.last_name)}
        //                     </button>
        //                     <div className="absolute shadow-2xl transition ease-in duration-200 focus:outline-none px-4 py-4 hover-target font-sans">
        //                         My Account
        //                         <button
        //                         onClick={() =>{
        //                             dispatch({ type: 'CustomerLogout' })
        //                             window.location.href = "/"
        //                         }}
        //                         className="my-2 px-3 rounded text-white transition duration-500 ease-in-out bg-safeRed transform hover:-translate-y-1 hover:scale-100 ...">
        //                             Logout
        //                         </button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </nav>
        //     <hr className={clsx(classes.headerRow, 'w-4/5 mx-auto border border-gray-200')} />
        // </div>
    );
}

export default withRouter(UserHeader);
