import './css/App.css';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import URLHelper from '../Helper/URLHelper';
import progressPng from '../assets/progress.png';
import jwt_decode from 'jwt-decode';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

import clsx from 'clsx';
import lock from '../assets/lock.png';
import phone from '../assets/phone.png';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import PublicHeader from './PublicHeader';
import SafeTechSnackbar from './SafeTechSnackbar';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	bootstrapInput: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		width: 'auto',
		padding: '10px 12px',
		margin: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#673ab7',
			boxShadow: '0 0 0 0.0rem #673ab7',
		},
	},
	progress: {
		margin: theme.spacing.unit * 2,
	},
	landingBackground:{
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    }
});

class LandingPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			modalCOpen: false,
			modalNewPassword: false,
			adminApprovalModal: false,
			tempUserType: '',
			tempUserId: '',
			tempUserToken: '',
			phone: '',
			password: '',
			new_password: '',
			confirm_password: '',
			snack: false,
			snackMsg: '',
			snackVariant: 'error',
			spinner: 'none',
			reset_password_spinner: 'none',
			BUTTONS: true,
		}
		
	}

	componentWillMount() {
		if (this.props.state.token !== '') {
			if (this.props.state.user_type == 'Admin') {
				// window.location.href = "/AccountListing"
				window.location.href = "/AccountList"
			}
			else {
				window.location.href = "/MainMenu"
			}
		}
	}

	inputHandler = (event, inputID) => {
		var value = event.target.value
		if (inputID == "phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ phone: value })
				}
			}
		}
		else {
			this.setState({ password: value })
		}
	}

	async declineResetPassword() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			var Data = {
				user_id: this.state.tempUserId,
				confirm_change_password: 0
			}
			return fetch(URLHelper.SetNewPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.state.tempUserToken
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ spinner: 'none', BUTTONS: false })
					if (res.status) {
						console.log(res)
						var token = res.data.token
						var user_id = jwt_decode(token).user_id
						var type = res.data.role
						var login_status = res.data.login_status
						this.props.LoginSuccess(token, user_id, type)
						if (login_status === 0) {
							if (type === 'Admin') {
								this.props.history.push("/AccountList")
							}
							else {
								this.props.history.push("/MainMenu")
							}
						}
						else {
							window.location.href = "/SetNewPassword"
						}
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
				})
				.catch((error) => {
					console.log(error)
					this.setState({ spinner: 'none' })
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	async ChangePassword() {
        if (navigator.onLine) {
            var Data = {
                user_id: this.props.state.user_id,
                password: this.state.new_password,
                confirm_change_password : 1
            }
            this.setState({ reset_password_spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.SetNewPassword, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ reset_password_spinner: 'none' })
                    if (res.status) {
                        console.log(res)
                        var token = res.data.token
                        var user_id = jwt_decode(token).user_id
                        var type = res.data.role
                        var login_status = res.data.login_status
                        this.props.LoginSuccess(token, user_id, type)
                        if(login_status === 0){
							this.setState({ modalNewPassword: false });
                            if (type === 'Admin') {
                                this.props.history.push("/AccountList")
                            }
                            else {
                                this.props.history.push("/MainMenu")
                            }
                        }
                        else{
                            window.location.href="/SetNewPassword"
                        }
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                    }
                })
                .catch((error) => {
                    console.log("Error==>" + error)
                    this.setState({ reset_password_spinner: 'none', BUTTONS: true, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

	FormSubmitResetPassword = () => {
		if (this.state.new_password == '') {
            this.setState({ snackMsg: "Password is Required.", snack: true, snackVariant: 'error' })
        }
        else if (this.state.confirm_password == '') {
            this.setState({ snackMsg: "Confirm Password is Required.", snack: true, snackVariant: 'error' })
        }
        else if (this.state.new_password !== this.state.confirm_password) {
            this.setState({ snackMsg: "Confirm Password isn't matched!!.", snack: true, snackVariant: 'error' })
        }
        else {
            this.ChangePassword()
        }
	}

	FormSubmit = (event) => {
		event.preventDefault();
		this.SignInCall()
	}

	async SignInCall() {
		if (navigator.onLine) {
			if (this.state.phone != '' && this.state.password != '') {
				this.setState({ spinner: 'block', BUTTONS: false })
				var Data = {
					phone_no: this.state.phone,
					password: this.state.password
				}
				// console.log("Sign In")
				// console.log(Data)
				return fetch(URLHelper.Login, {
					method: 'POST',
					headers: {
						'Accept': 'Application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(Data),
				}).then((Response) => Response.json())
					.then((res) => {
						this.setState({ spinner: 'none', BUTTONS: false })
						if (res.status) {
							// console.log('SignInCall ::', res)
							const { token, role, login_status, first_name, last_name } = res.data;
							var user_id = jwt_decode(token).user_id
							this.props.LoginSuccess(token, user_id, role)
							if (login_status !== 1) {
								this.props.RemoveAdminToken()
								if (role === 'Admin') {
									this.props.AdminLoginSuccess(first_name, last_name)
									this.props.history.push("/AccountList")
								}
								else {
									this.props.history.push("/MainMenu")
								}
							}
							else {
								this.setState({ modalCOpen: true, tempUserId: user_id, tempUserType: role, tempUserToken: token })
								this.props.RemoveAdminToken()
								// this.props.history.push("/SetNewPassword")
							}
						}
						else {
							this.props.RemoveAdminToken()
							this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
						}
					})
					.catch((error) => {
						console.log(error)
						this.props.RemoveAdminToken()
						this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
					})
			}
			else {
				this.setState({ spinner: 'none' })
				if (this.state.phone != '' && this.state.password == '') {
					this.setState({ snackMsg: "Password is required.", snack: true, snackVariant: 'error' })
				}
				else if (this.state.phone == '' && this.state.password != '') {
					this.setState({ snackMsg: "Phone is required.", snack: true, snackVariant: 'error' })
				}
				else {
					this.setState({ snackMsg: "Phone and Password are required.", snack: true, snackVariant: 'error' })
				}
			}
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	handleCloseAdminApprovalModal = () => {
		this.setState({ adminApprovalModal: false })
	}

	hideSnackBar = () => {
		this.setState({ snack: false })
	}
	
	render() {
		const { classes } = this.props;
		return (
			<div className={clsx(classes.landingBackground, 'h-screen')}>
				{/* <Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={this.state.snack}
					autoHideDuration={1500}
					onClose={() => this.setState({ snack: false })}
				>
					<SnackbarContent
						className="error"
						aria-describedby="client-snackbar"
						message={this.state.snackMsg}
					/>
				</Snackbar> */}
				<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
				<Dialog maxWidth={'xs'} fullWidth={false} open={this.state.modalNewPassword} onClose={() => {this.setState({ modalNewPassword: false })} } aria-labelledby="form-dialog-title">
					<DialogContent>
						<div className="w-64">
							<div className="mb-4">
								<p className="text-primary text-sm">Enter your new password</p>
							</div>
							<div className="flex items-center border-solid border-2 rounded mb-2">
								<IconButton disabled={true} className="pl-4 my-6" aria-label="lock">
									<img src={lock}/>
								</IconButton>
								<InputBase
									className="flex"
									id="new_password"
									type="password"
									placeholder="Password"
									value={this.state.new_password}
									onChange={(event) => this.setState({ new_password: event.target.value })}
									inputProps={{ 'aria-label': 'new password' }}
									/>
							</div>
							<div className="flex items-center border-solid border-2 rounded">
								<IconButton disabled={true} className="pl-4 my-6" aria-label="lock">
									<img src={lock}/>
								</IconButton>
								<InputBase
									className="flex"
									type="password"
									id="confirm_password"
									placeholder="Confirm Password"
									value={this.state.confirm_password}
									onChange={(event) => this.setState({ confirm_password: event.target.value })}
									inputProps={{ 'aria-label': 'confirm password' }}
								/>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<Grid container>
							<div className="flex flex-col w-full px-4">
								<div className="text-center">
									<button
										type="button"
										disabled={(this.state.reset_password_spinner === 'block')}
										onClick={() => this.FormSubmitResetPassword()}
										className="bg-primary w-full text-white py-4 my-2 shadow-xl rounded font-sans text-xs md:text-sm">
										{(this.state.reset_password_spinner === 'block') ? <img className="h-6 mx-auto" src={progressPng} alt='progress'/> : 'Set password' }
									</button>
								</div>
							</div>
						</Grid>
					</DialogActions>
				</Dialog>
				<Dialog
					open={this.state.modalCOpen}
					onClose={() => this.setState({ modalCOpen: false })}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title"></DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">Do you want to set a new password?</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.declineResetPassword()} color="primary">
							No
							</Button>
						<Button onClick={() => {
							this.setState({ modalCOpen: false, modalNewPassword: true, BUTTONS: true })
							// this.props.history.push("/SetNewPassword")
						}} color="primary" autoFocus>
							Yes
							</Button>
					</DialogActions>
				</Dialog>
				<PublicHeader propsState={this.props.state}/>
				<div className="w-full max-w-xs mx-auto mt-30p xl:mt-32">
					<form noValidate autoComplete="off" onSubmit={this.FormSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
						<div>
							<div className="text-xl mx-auto mb-4 text-primary text-center">Welcome back!</div>
						</div>
						<div className="mb-4">
							<div className="flex items-center border-solid border-2 rounded">
								<IconButton disabled={true} className="px-4 my-6" aria-label="phone">
									<img src={phone}/>
								</IconButton>
								<InputBase
									className="flex"
									type="tel"
									placeholder="Phone #"
									value={this.state.phone}
									onChange={(event) => this.inputHandler(event, "phone")}
									inputProps={{ 'aria-label': 'phone' }}
								/>
							</div>
						</div>
						<div className="mb-4">
							<div className="flex items-center border-solid border-2 rounded">
								<IconButton disabled={true} className="pl-4 my-6" aria-label="lock">
									<img src={lock}/>
								</IconButton>
								<InputBase
									className="flex"
									type="password"
									placeholder="Password"
									value={this.state.password}
									onChange={(event) => this.inputHandler(event, "password")}
									inputProps={{ 'aria-label': 'password' }}
								/>
							</div>
							{/* <p className="text-red-500 text-xs italic">Please choose a password.</p> */}
						</div>
						<div className="flex flex-col items-center justify-between">
								<button
									type="submit"
									disabled={(this.state.spinner === 'block')}
									className="w-full shadow-2xl bg-primary hover:bg-secondary
									transition ease-in duration-200
									border text-white text-base py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline">
									{(this.state.spinner === 'block') ? <img className="h-6 mx-auto" src={progressPng} alt='progress'/> : 'Sign In' }
								</button>
								<button
									type="button"
									className="inline-block align-baseline text-sm text-safeGray hover:text-primary"
									onClick={() => this.props.history.push("/ForgotPassword")}
								>Forgot Password?</button>
							</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		LoginSuccess: (token, user_id, user_type) => {
			dispatch({ type: 'CustomerLoginSuccess', value: { token, user_id, user_type } })
		},
		AdminLoginSuccess: (first_name, last_name) => {
			dispatch({ type: 'AdminLoginSuccess', value: { first_name, last_name } })
		},
		RemoveAdminToken: () => dispatch({ type: 'RemoveAdminToken' })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LandingPage));
