import './css/App.css';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class Dummy extends Component {
	constructor(props) {
		super(props)
		this.state = {
			address: '',
		}
		this.addressReference = React.createRef();
	}

	componentDidMount() {
		const autocompleteFormField = this.addressReference.current;
		console.log(autocompleteFormField)
		const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
			types: ['address'],
		});
		console.log("autocomplete")
		console.log(autocomplete)
		window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			console.log("DATA==>")
			console.log(place)
			if (place.address_components != null) {
				place.address_components.map((value, index) => {
					if (value.types.includes('postal_code')) {
						console.log("ADDRESS POSTAL==>" + value.long_name)
					}
					if (value.types.includes('administrative_area_level_1')) {
						console.log("ADDRESS STATE==>" + value.long_name)
					}
					if (value.types.includes('locality')) {
						console.log("ADDRESS CITY==>" + value.long_name)
					}
				})
			}
		});
	}

	render() {
		return (
			<div className="App">
				<center>
					<input
						id="address"
						label="Address"
						type="text"
						margin="normal"
						style={{ width: '50%' }}
						value={this.state.address}
						ref={this.addressReference}
						onChange={(event) => {
							console.log("Change")
							this.setState({ address: event.target.value })
						}}
						variant="outlined"
					/>
				</center>
			</div>
		);
	}
}


export default Dummy;
