import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid, Button, CircularProgress, Snackbar, SnackbarContent, TextField, InputAdornment, Tooltip,
	Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Table, TableBody, TableCell,
	TableHead, TableRow, Chip, Switch, FormControl, Select, InputLabel, MenuItem, Divider, Paper, TableContainer, Checkbox
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import URLHelper from '../Helper/URLHelper';
import { connect } from 'react-redux';
import { Search } from '@material-ui/icons';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';
import InfiniteScroll from "react-infinite-scroll-component";
import { fail } from 'assert';
import AppHelper from '../Helper/AppHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import Header from './Header';
import IconButton from '@material-ui/core/IconButton';
import MaskedInput from 'react-maskedinput';
import whiteLock from '../assets/white_lock.png';
import editPng from '../assets/edit.png';
import plusPng from '../assets/plus.png';
import AdminHeader from './AdminHeader';
import progressPng from '../assets/progress.png';
import clsx from 'clsx';
import SafeTechSnackbar from './SafeTechSnackbar';
import "react-tabs/style/react-tabs.css";

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	list: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	signOutButton: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	button: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		marginRight: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	toggleButton: {
		cursor: 'pointer',
		float: 'left',
		width: '1.5em',
		height: '1.5em',
		paddingTop: '2%'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
});

const PrimaryCheckbox = withStyles({
	root: {
		'&$checked': {
			color: '#273359',
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

const GreenSwitch = withStyles({
	switchBase: {
		color: green[300],
		'&$checked': {
			color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {},
})(Switch);

class AccountList extends Component {

	constructor(props) {
		console.log('AccountList component :', props)
		super(props)
		this.state = {
			selected_tab_index: 0,
			first_name: '',
			credit: '',
			min_credit: '',
			credit_display: '',
			spinner: 'none',
			logSpinner: 'none',
			infoSpinner: 'none',
			billing_spinner: 'none',
			accounts_spinner: 'none',
			cc_action_spinner: 'none',
			user_detail_spinner: 'none',
			subscription_spinner: 'none',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			BUTTONS: false,
			Page: 1,
			billingPage: 1,
			logPage: 1,
			limit: 20,
			hasMore: true,
			hasMoreBillingData: false,
			hasMoreLogData: false,
			// hasMore: false,
			search: '',
			Accounts: [
				// { user_id: 360, name: 'A', contact: '347-452-3919', balance: '300.00', auto_replenished: true },
				// { user_id: 380, name: 'B', contact: '347-452-3919', balance: '0.00', auto_replenished: false },
				// { user_id: 390, name: 'C', contact: '347-452-3919', balance: '1090.00', auto_replenished: false },
				// { user_id: 140, name: 'D', contact: '347-452-3919', balance: '0.00', auto_replenished: false },
				// { user_id: 400, name: 'E', contact: '347-452-3919', balance: '70.00', auto_replenished: false },
			],
			DisplayAccounts: [],
			modalOpen: false,
			modalCPOpen: false,
			modalUBOpen: false,
			modalDPOpen: false,
			setUserIdToChangePassword: null,
			temp_password: '',
			confirm_temp_password: '',
			change_password: '',
			new_minutes: 0,
			confirm_change_password: '',
			account_id: 0,
			cardList: [],
			billingList: [],
			userLogs: [],
			userSubcribedPlans: [],
			user_first_name: '',
			user_last_name: '',
			user_address: '',
			user_city: '',
			user_state: '',
			user_zip: '',
			user_phone: '',
			user_email: '',
			is_set_auto_replenish: '',
			auto_replenish_card_id: '',
			input_field_disabled: true,
			edit_button_disabled: true,
			remove_credit_card_id: 0,
			group_policies: [],
			default_policy_string: '',
			groups: [],
			default_selected_index: [],
			selected_index: [],
			policy_string: '',
			current_selected_plan_index: -1,
			current_selected_plan_id: 0,
			current_selected_plan_amount: 0,
			plan_start_date: moment(new Date(), "DD-MM-YYYY"),
			plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
			modelConfirmDelete: false,
			subscription_plan_action: '',
			action_dialog_message: '',
			action_subscription_plan_id: 0,
			subscription_plans: [],
			filterEvent: 'all',
			currentUserTab: 'customer_info',
			is_billing_address: false,
			card_street_number: '',
			card_address: '',
			card_city: '',
			card_state: '',
			card_zip: '',
			is_save_card_account: false,
			is_primary_card: false,
			is_agree_tnc: false,
			name_on_card: '',
			card_number: '',
			expiry_date: '',
			cvv: '',
			messageDialog: false
		}
		this.addressReference = React.createRef();
		this.handleGroupChange = this.handleGroupChange.bind(this);
		this.handleSubscriptionPlanChange = this.handleSubscriptionPlanChange.bind(this);
	}

	handleClickOpen = (user_id) => {
		// console.log("user id to set temp pass : ",user_id);
		this.setState({ modalOpen: true, setUserIdToChangePassword: user_id })
	}

	handleClose = () => {
		this.setState({ modalOpen: false, spinner: 'none', temp_password: '', confirm_temp_password: '' })
	}

	handleCloseMessageDialog = () => {
		this.setState({ messageDialog: false })
	}

	handleChangePasswordClickOpen = (Account) => {
		// console.log("user id to set temp pass : ", Account.user_id);
		this.setState({ modalCPOpen: true, setUserIdToChangePassword: Account.user_id })
	}

	handleChangePasswordClose = () => {
		this.setState({ modalCPOpen: false, spinner: 'none', change_password: '', confirm_change_password: '' })
	}

	handleUpdateMinutes = (Account) => {
		// console.log("handleUpdateMinutes Account.user_id : ",Account.user_id);
		this.setState({ modalUBOpen: true, setUserIdToChangePassword: Account.user_id })
	}

	updateCurrentBalance = () => {
		// console.log("updateCurrentBalance : ",this.state.new_minutes);
		if (this.state.new_minutes === '' || this.state.new_minutes === 0) {
			this.setState({ snackMsg: "Please enter some minutes.", snack: true, snackVariant: 'error' })
		}
		// else if(this.state.new_minutes !== '' && this.state.new_minutes < -15){
		// 	this.setState({ snackMsg: "You can decrease balance upto 15 minutes only.", snack: true, snackVariant: 'error' })
		// }else if(this.state.new_minutes !== '' && this.state.new_minutes > 10){
		// 	this.setState({ snackMsg: "You can increase balance upto 10 minutes only.", snack: true, snackVariant: 'error' })
		// }
		else {
			// console.log("passed all condition.. : ");
			this.updateCurrentBalanceClicked()
		}
	}

	async updateCurrentBalanceClicked() {
		if (navigator.onLine) {
			let Data = {
				admin_token: this.props.state.token,
				user_id: this.state.setUserIdToChangePassword,
				minutes: this.state.new_minutes,
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.SetNewBalance, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log(res)
						// this.fetchUserData(1, '')
						this.setState({ spinner: 'none', modalUBOpen: false, snackMsg: res.message, snack: true, snackVariant: 'success', new_minutes: 0, BUTTONS: true })
						window.location.href = "/AccountList"
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	handleUpdateMinutesDialogClose = () => {
		this.setState({ modalUBOpen: false, spinner: 'none', new_minutes: 0 })
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	componentDidUpdate(prevProps) {
		// console.log('componentDidUpdate ::', prevProps);
		if (this.addressReference.current) {
			const autocompleteFormField = this.addressReference.current;
			const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
				types: ['address'],
			});
			window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
				this.setState({ card_street_number: '', card_address: '', card_zip: '', card_state: '', card_city: '' })
				const place = autocomplete.getPlace();
				// console.log('PLACE ===>', place);
				if (place.address_components != null) {
					place.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ card_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setState({ card_zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setState({ card_state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setState({ card_city: value.long_name })
						}
					})
				}
			});
		}
	}

	componentDidMount() {
		this.GetGroupPolicies()
		this.getSubscriptionPlans(1, '')
		// const autocompleteFormField = this.addressReference.current;
		// const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
		// 	types: ['address'],
		// });
		// window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
		// 	this.setState({ card_street_number: '', card_address: '' , card_zip: '', card_state: '', card_city: '' })
		// 	const place = autocomplete.getPlace();
		// 	// console.log('PLACE ===>', place);
		// 	if (place.address_components != null) {
		// 		place.address_components.map((value, index) => {
		// 			if (value.types.includes('street_number')) {
		// 				this.setState({ card_street_number: value.long_name })
		// 			}
		// 			if (value.types.includes('route')) {
		// 				this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
		// 			}
		// 			if (value.types.includes('postal_code')) {
		// 				this.setState({ card_zip: value.long_name })
		// 			}
		// 			if (value.types.includes('administrative_area_level_1')) {
		// 				this.setState({ card_state: value.long_name })
		// 			}
		// 			if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
		// 				this.setState({ card_city: value.long_name })
		// 			}
		// 		})
		// 	}
		// });
	}

	componentWillMount() {
		if (this.props.state.token === '' || this.props.state.user_type === 'User') {
			window.location.href = "/"
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true, snackVariant: 'error' })
				this.props.UpdateMSG('')
			}
			this.fetchUserData(1, '').then(() => this.setState({ DisplayAccounts: this.state.Accounts, BUTTONS: true }))
		}

	}

	async getSubscriptionPlans(Page, Search) {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			// console.log(URLHelper.GetSubscriptionPlans + "/" + Page + "?search=" + Search)
			return fetch(URLHelper.GetSubscriptionPlans + "/" + Page + "?search=" + Search, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						// console.log('GetSubscriptionPlans :', res)
						this.setState({ subscription_plans: res.data, spinner: 'none' })
					}
					else {
						console.log("response of getSubscriptionPlans ::", res.status);
						// this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none', })
					}
				})
					.catch((error) => {
						this.setState({ spinner: 'none', button: '' })
						this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
					})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	async fetchUserData(Page, Search) {
		if (navigator.onLine) {
			this.setState({ accounts_spinner: 'block', BUTTONS: false })
			// console.log(URLHelper.GetUsers + "/" + Page + "?search=" + Search)
			return fetch(URLHelper.GetUsers + "/" + Page + "?search=" + Search, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ accounts_spinner: 'none', BUTTONS: true })
					if (res.status) {
						// console.log('GetUsers :',res)
						let NewAccounts = this.state.Accounts
						for (let i = 0; i < res.data.length; i++) {
							//console.log(res.data[i])
							NewAccounts.push({
								user_id: res.data[i].user_id, name: res.data[i].first_name + " " + res.data[i].last_name,
								contact: res.data[i].phone_no, address: res.data[i].address, city: res.data[i].city, state: res.data[i].state, zip: res.data[i].zip,
								balance: res.data[i].credit_balance, auto_replenished: res.data[i].auto_replenish
							})
						}
						// if(res.data.length == 1){
						// 	this.gotToTab(res.data[0].user_id);
						// }
						this.setState({ Accounts: NewAccounts, accounts_spinner: 'none' })
					}
					else {
						// console.log("response of get user ::", res.status);
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, accounts_spinner: 'none', })
					}
				})
					.catch((error) => {
						this.setState({ accounts_spinner: 'none', button: '', hasMore: true })
						this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true, hasMore: true })
					})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	searchChange = (event) => {
		let search = event.target.value
		this.setState({ search: search })
		if (search == '') {
			// console.log("Hi")
			this.setState({ search: '', Page: 1, Accounts: [], hasMore: true, policy_string: '', edit_button_disabled: true, input_field_disabled: true })
			this.fetchUserData(1, '')
			this.setState({ account_id: 0 }, () => {
				this.gotToTab(this.state.account_id)
			})
		}
		else {
			this.setState({ search: search })
		}
	}

	searchAccount = () => {
		if (this.state.search.length > 0) {
			this.setState({ search: this.state.search, Page: 1, Accounts: [] })
			this.fetchUserData(1, this.state.search)
		}
	}

	actionClick = (type, account_id) => {
		// console.log("actionClick called :: ", type, account_id)
		// console.log("actionClick called @@ :: ", this.state.user_address, this.state.user_city, this.state.user_state, this.state.user_zip )
		this.props.UpdateCustomer(account_id, '', '', this.state.user_address, this.state.user_city, this.state.user_state, this.state.user_zip)
		// // if (type === "item_refill") {
		if (type === "auto") {
			this.props.history.push("/Plans", { PropsData: { type: 'auto-refill', action_by: 'admin' } })
		} else {
			this.props.history.push("/Plans", { PropsData: { type: 'refill', action_by: 'admin' } })
		}
	}

	async toggleAutoReplenish(user_id, status) {
		if (navigator.onLine) {
			let Data = {
				user_id: user_id,
				status: status
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.ToggleAutoReplenish, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log('ToggleAutoReplenish :',res)
						let newStatus = (status === "1") ? 1 : 0;
						// this.fetchUserData(1, '')
						this.setState({ is_set_auto_replenish: newStatus, spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'success', BUTTONS: true })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					// console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	getTempPassword = () => {
		// console.log("here : ",this.state.temp_password,this.state.confirm_temp_password);
		if (this.state.temp_password == '') {
			this.setState({ snackMsg: "Temp Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.confirm_temp_password == '') {
			this.setState({ snackMsg: "Confirm Temp Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.temp_password !== this.state.confirm_temp_password) {
			this.setState({ snackMsg: "Confirm Temp Password isn't matched!!.", snack: true, snackVariant: 'error' })
		}
		else {
			this.actionCreateTempPasswordClick()
		}
	}

	async actionCreateTempPasswordClick() {
		if (navigator.onLine) {
			let Data = {
				admin_token: this.props.state.token,
				user_id: this.state.setUserIdToChangePassword,
				temp_password: this.state.temp_password
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.SetTempPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log(res)
						this.setState({ spinner: 'none', modalOpen: false })
						this.state.temp_password = ''
						this.state.confirm_temp_password = ''
						this.setState({ messageDialog: true, BUTTONS: true })
						// this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					//console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true })
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	getChangeCurrentPassword = () => {
		// console.log("here : ",this.state.temp_password,this.state.confirm_temp_password);
		if (this.state.change_password == '') {
			this.setState({ snackMsg: "Change Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.confirm_change_password == '') {
			this.setState({ snackMsg: "Confirm Change Password is Required.", snack: true, snackVariant: 'error' })
		}
		else if (this.state.change_password !== this.state.confirm_change_password) {
			this.setState({ snackMsg: "Confirm Change Password isn't matched!!.", snack: true, snackVariant: 'error' })
		}
		else {
			this.actionChangeCurrentPasswordClick()
		}
	}

	async actionChangeCurrentPasswordClick() {
		if (navigator.onLine) {
			let Data = {
				user_id: this.state.setUserIdToChangePassword,
				password: this.state.change_password,
				confirm_change_password: 1
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.SetNewPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						// console.log(res)
						this.state.change_password = ''
						this.state.confirm_change_password = ''
						this.setState({ spinner: 'none', modalCPOpen: false, snackMsg: res.message, snack: true, snackVariant: 'success', BUTTONS: true })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	fetchMoreData = () => {
		if (this.state.Accounts.length == (this.state.Page * this.state.limit)) {
			// console.log(this.state.Accounts.length)
			// console.log(this.state.Page * this.state.limit)
			this.setState({
				Page: this.state.Page + 1, spinner: true, hasMore: true
			}, () => {
				this.fetchUserData(this.state.Page, this.state.search)
			});
		}
		else {
			// this.setState({ hasMore: false, spinner: false, spinner: 'none' })
			this.setState({ hasMore: true, spinner: false, spinner: 'none' })
		}
	}

	fetchMoreBillingData = () => {
		if (this.state.billingList.length == (this.state.billingPage * this.state.limit)) {
			this.setState({
				billingPage: this.state.billingPage + 1, spinner: true
			}, () => {
				this.fetchBillingData(this.state.account_id, this.state.billingPage)
			});
		}
		else {
			this.setState({ hasMoreBillingData: false, spinner: false, spinner: 'none' })
		}
	}

	fetchMoreLogData = () => {
		if (this.state.userLogs.length == (this.state.logPage * this.state.limit)) {
			this.setState({
				logPage: this.state.logPage + 1, spinner: true
			},
				() => {
					this.fetchUserLogs(this.state.account_id, this.state.logPage)
				});
		}
		else {
			this.setState({ hasMoreLogData: false, spinner: false, spinner: 'none' })
		}
	}

	handleChangeFilterEvent = (event) => {
		// console.log('called handleChangeFilterEvent :', event.target.value)
		this.setState({ userLogs: [], filterEvent: event.target.value, logPage: 1, logSpinner: 'block' },
			() => {
				this.fetchUserLogs(this.state.account_id, this.state.logPage)
			})
	}

	gotToTab = (account_id) => {
		// console.log("inside goto tab account_id : ", account_id)
		if (account_id > 0) {
			this.resetBillingTab();
			this.setState({
				account_id: account_id, billingPage: 1, filterEvent: 'all', currentUserTab: 'customer_info',
				logPage: 1, cardList: [], billingList: [], userLogs: [],
				current_selected_plan_id: 0, current_selected_plan_index: -1, current_selected_plan_amount: 0, selected_tab_index: 0,
				plan_start_date: moment(new Date(), "DD-MM-YYYY"), plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days')
			},
				() => {
					this.fetchCurrentUserDetails(this.state.account_id)
					this.fetchCardListing(this.state.account_id)
					this.fetchBillingData(this.state.account_id, this.state.billingPage)
					this.fetchUserLogs(this.state.account_id, this.state.logPage)
					this.fetchUserSubscribedPlans(this.state.account_id, 1)
				})
		} else {
			this.setState({
				selected_tab_index: 0,
				account_id: 0,
				cardList: [],
				billingList: [],
				userLogs: [],
				billingPage: 1,
				logPage: 1,
				user_first_name: '',
				user_last_name: '',
				user_address: '',
				user_city: '',
				user_state: '',
				user_zip: '',
				user_phone: '',
				user_email: '',
				policy_string: '',
				current_selected_plan_id: 0,
				current_selected_plan_index: -1,
				current_selected_plan_amount: 0,
				plan_start_date: moment(new Date(), "DD-MM-YYYY"),
				plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
			})
		}
	}

	resetBillingTab = () => {
		this.setState({
			is_billing_address: false, card_address: '', card_city: '', card_state: '', card_zip: '',
			is_save_card_account: false, is_primary_card: false, is_agree_tnc: false, name_on_card: '',
			card_number: '', expiry_date: '', cvv: '', currentUserTab: 'customer_info'
		});
	}

	handleDateChange = (type, date) => {
		console.log('type :', type)
		console.log('date :', date)
		if (type === 'start') {
			if (date !== null) {
				// console.log("start :", moment(date).format('YYYY-MM-DD'));
				this.setState({ plan_start_date: date })
				// this.setState({ plan_start_date: moment(date).format('YYYY-MM-DD') })
			} else {
				this.setState({ plan_start_date: moment(new Date(), "DD-MM-YYYY") })
			}
		}
		if (type === 'end') {
			if (date !== null) {
				// if (date < this.state.plan_start_date) {
				// 	this.setState({ snackMsg: "End date cann't be less than start date.", snack: true, snackVariant: 'error' })
				// } else {
				// console.log("end :", moment(date).format('YYYY-MM-DD'));
				this.setState({ plan_end_date: date })
				// this.setState({ plan_end_date: moment(date).format('YYYY-MM-DD') })
				// }
			} else {
				this.setState({ plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days') })
			}
		}
	}

	async fetchCurrentUserDetails(accountid) {
		if (navigator.onLine) {
			this.setState({ user_detail_spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetUserDetail + "/" + accountid, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ user_detail_spinner: 'none', BUTTONS: true })
					if (res.status) {
						let userData = res.data[0];
						// console.log('userData.groups :', userData.groups)
						let groupArray = userData.groups.split(",");
						// console.log('groupArray :', groupArray)
						// console.log('this.state.group_policies :',this.state.group_policies)
						let newGroupArr = [];
						let SelectedIndex = [];
						this.state.group_policies.forEach((policy, index) => {
							groupArray.forEach(key => {
								if (policy.group_policy_id == key) {
									SelectedIndex.push(index)
									newGroupArr.push(policy.group_policy_id)
								}
							})
						})

						// console.log('SelectedIndex :',SelectedIndex)
						// console.log('userData.group_policies :',userData.group_policies)
						// console.log('this.state.default_policy_string :',this.state.default_policy_string)

						this.setState({
							user_first_name: userData.first_name,
							user_last_name: userData.last_name,
							user_address: userData.address,
							user_city: userData.city,
							user_state: userData.state,
							user_zip: userData.zip,
							user_phone: userData.phone_no,
							user_email: userData.email,
							is_set_auto_replenish: userData.auto_replenish,
							auto_replenish_card_id: userData.auto_replenish_creditcard_id,
							policy_string: (userData.group_policies !== "") ? userData.group_policies : '',
							selected_index: (SelectedIndex.length > 0) ? SelectedIndex : [],
							// selected_index: [{group_policy_id: 4, group_policy_name: "Change Password", group_policy: "CP=Change Password"},
							// {group_policy_id: 5, group_policy_name: "Add Balance", group_policy: "AB=Add Balance"}],
							groups: (newGroupArr.length > 0) ? newGroupArr : [],
							edit_button_disabled: false,
							input_field_disabled: true,
						})
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
				})
				.catch((error) => {
					// console.log("Error==>" + error)
					this.setState({ button: '', snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error', BUTTONS: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	async fetchCardListing(accountid) {
		let newCardList = []
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			let Data = {
				start: 0,
				user_id: accountid,
			}
			return fetch(URLHelper.GetCardListing, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data)
			}).then((Response) => {
				if (Response.status == 404) {
					this.setState({ spinner: 'none', cardList: newCardList })
				} else {
					Response.json().then((res) => {
						this.setState({ spinner: 'none', BUTTONS: true })
						if (res.status) {
							// console.log(res)
							for (let i = 0; i < res.data.length; i++) {
								newCardList.push({ creditcard_id: res.data[i].creditcard_id, last_four: res.data[i].last_four, default_creditcard_id: res.data[i].default_creditcard_id, auto_replenish_creditcard_id: res.data[i].auto_replenish_creditcard_id })
							}
							this.setState({ cardList: newCardList })
						}
						else {
							this.setState({ spinner: 'none', snackMsg: res.message, snack: res.message ? true : false, BUTTONS: true, cardList: newCardList })
						}
					})
						.catch((error) => {
							this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true, cardList: newCardList })
						})
				}
			})
				.catch((error) => {
					this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true, cardList: newCardList })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	async fetchBillingData(accountid, page) {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			console.log("GetUserBilling : ", URLHelper.GetUserBilling + "/" + accountid + "/" + page)
			return fetch(URLHelper.GetUserBilling + "/" + accountid + "/" + page, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 404) {
					// this.setState({ spinner: 'none', billingList: [], hasMoreBillingData: true })
					this.setState({ spinner: 'none', hasMoreBillingData: false })
				}
				else {
					Response.json().then((res) => {
						this.setState({ spinner: 'none', BUTTONS: true })
						if (res.status) {
							// console.log("result transaction list : ", res.data.length)
							let newBillingList = this.state.billingList
							for (let i = 0; i < res.data.length; i++) {
								//console.log(res.data[i])
								newBillingList.push({ transaction_id: res.data[i].transaction_id, date_created: res.data[i].date_created, method: res.data[i].method + " " + res.data[i].last_four, actual_amount_paid: parseFloat(res.data[i].actual_amount_paid), plan_id: res.data[i].plan_id })
							}
							this.setState({ billingList: newBillingList, hasMoreBillingData: res.data.length < this.state.limit ? false : true })
						}
						else {
							this.setState({ snackMsg: res.message, hasMoreBillingData: false, snack: res.message ? true : false, BUTTONS: true, spinner: 'none', billingList: [] })
						}
					}).catch((error) => {
						this.setState({ spinner: 'none', hasMoreBillingData: false, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', billingList: [] })
					})
				}
			})
				.catch((error) => {
					this.setState({ spinner: 'none', hasMoreBillingData: false, snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', billingList: [] })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", hasMoreBillingData: false, snack: true, snackVariant: 'error', billingList: [] })
		}
	}

	async fetchUserLogs(accountid, page) {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			// console.log("GetUserLogs : ", URLHelper.GetUserLogs + "/" + accountid + "/" + page);
			return fetch(URLHelper.GetUserLogs + "/" + accountid + "/" + page + "/" + this.state.filterEvent, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 404) {
					// this.setState({ spinner: 'none', userLogs: [], hasMoreLogData: false })
					this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs })
				}
				else {
					Response.json()
						.then((res) => {
							this.setState({ logSpinner: 'none', spinner: 'none', BUTTONS: true })
							if (res.status) {
								// console.log("result log list : ", res)
								let newLogList = (this.state.filterEvent !== '' && page === 1) ? [] : this.state.userLogs
								for (let i = 0; i < res.data.length; i++) {
									//console.log(res.data[i])
									newLogList.push({ date: res.data[i].date, event_name: res.data[i].event_name, station: res.data[i].station, amount: parseFloat(res.data[i].amount).toFixed(2), running_balance: res.data[i].running_balance })
								}
								this.setState({ userLogs: newLogList, hasMoreLogData: res.data.length < this.state.limit ? false : true })
							}
							else {
								this.setState({ snackMsg: res.message, hasMoreLogData: false, snack: res.message ? true : false, BUTTONS: true, spinner: 'none', userLogs: [] })
							}
						})
						.catch((error) => {
							this.setState({
								logSpinner: 'none',
								spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
								snack: true, snackVariant: 'error', BUTTONS: true, userLogs: []
							})
						})
				}
			}).catch((error) => {
				this.setState({
					logSpinner: 'none',
					spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
					snack: true, snackVariant: 'error', BUTTONS: true, userLogs: []
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", hasMoreLogData: false, snack: true, snackVariant: 'error', userLogs: [] })
		}
	};

	async fetchUserSubscribedPlans(accountid, page) {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			// console.log("GetUserSubscribedPlans : ", URLHelper.GetUserSubscribedPlans + "/" + accountid + "/" + page);
			// return fetch(URLHelper.GetUserSubscribedPlans + "/" + accountid + "/" + page, {
			return fetch(URLHelper.GetUserSubscribedPlans + "/" + accountid, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 404) {
					this.setState({ spinner: 'none', hasMoreLogData: false, userSubcribedPlans: [] })
				}
				else {
					Response.json()
						.then((res) => {
							this.setState({ spinner: 'none', BUTTONS: true })
							if (res.status) {
								// console.log("result fetchUserSubscribedPlans data : ", res.data)
								this.setState({ userSubcribedPlans: res.data })
							}
							else {
								this.setState({ snackMsg: res.message, userSubcribedPlans: [], hasMoreLogData: false, snack: res.message ? true : false, BUTTONS: true, spinner: 'none' })
							}
						})
						.catch((error) => {
							this.setState({
								spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
								snack: true, snackVariant: 'error', BUTTONS: true, userSubcribedPlans: []
							})
						})
				}
			}).catch((error) => {
				this.setState({
					spinner: 'none', button: '', hasMoreLogData: false, snackMsg: "Something went wrong.",
					snack: true, snackVariant: 'error', BUTTONS: true, userSubcribedPlans: []
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", hasMoreLogData: false, snack: true, snackVariant: 'error', userLogs: [] })
		}
	};

	handleSubscriptionPlanChange = (event, value) => {
		// console.log('handleSubscriptionPlanChange')
		// console.log('event :', event)
		// console.log('value :', value)
		let index = -1;
		if(value){
			index = this.state.subscription_plans.findIndex(i => i.subscription_plan_id === value.subscription_plan_id);
			// console.log('index ==>', index)
		}
		if (value !== null && value.subscription_plan_id > 0) {
			this.setState({ current_selected_plan_index: index, current_selected_plan_id: value.subscription_plan_id, current_selected_plan_amount: value.plan_price });
		} else {
			this.setState({ current_selected_plan_index: -1, current_selected_plan_id: 0, current_selected_plan_amount: 0 });
		}
	}

	saveUserSubscriptionPlan = () => {
		// console.log('saveUserSubscriptionPlan start date :', this.state.plan_start_date)
		// console.log('saveUserSubscriptionPlan end date :', this.state.plan_end_date)
		if (this.state.plan_start_date !== '' && this.state.plan_end_date !== '') {
			if (this.state.plan_start_date < this.state.plan_end_date) {
				if (this.state.current_selected_plan_id > 0) {
					this.addSubscriptionPlan()
				} else {
					this.setState({ snackMsg: "Please select a subscription plan.", snack: true, snackVariant: 'error' })
				}
			} else {
				this.setState({ snackMsg: "Start date can't be greater than Or equal to End date.", snack: true, snackVariant: 'error' })
			}
		} else {
			this.setState({ snackMsg: "Please select plan start date and end date.", snack: true, snackVariant: 'error' })
		}
	}

	addSubscriptionPlan = () => {
		if (navigator.onLine) {
			this.setState({ subscription_spinner: 'block' })
			// console.log('start_date format :', this.state.plan_start_date)
			// console.log('end_date format :', this.state.plan_end_date)
			let Data = {
				user_id: this.state.account_id,
				subscription_plan_id: this.state.current_selected_plan_id,
				amount: this.state.current_selected_plan_amount,
				start_date: moment(this.state.plan_start_date).format('YYYY-MM-DD'),
				end_date: moment(this.state.plan_end_date).format('YYYY-MM-DD'),
			}
			return fetch(URLHelper.AddUserSubscriptionPlan, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ subscription_spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE")
						this.setState({
							snackMsg: res.message, snack: true, snackVariant: 'success', subscription_spinner: 'none',
							search: '', Page: 1, Accounts: [], input_field_disabled: true
						})
						this.fetchUserData(1, '')
						this.gotToTab(this.state.account_id)
					}
					else {
						this.setState({
							snackMsg: res.message, snack: true, snackVariant: 'error', subscription_spinner: 'none',
							// current_selected_plan_id: 0,
							// current_selected_plan_index: -1,
							// current_selected_plan_amount: 0,
							// plan_start_date: moment(new Date(), "DD-MM-YYYY"),
							// plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
						})
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({
						snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', subscription_spinner: 'none',
						current_selected_plan_id: 0,
						current_selected_plan_index: -1,
						current_selected_plan_amount: 0,
						plan_start_date: moment(new Date(), "DD-MM-YYYY"),
						plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
					})
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	SubscriptionPlanAction = (action, plan_id) => {
		let is_active = ''
		if (this.state.action_dialog_message === 'Deactivate') {
			is_active = '0'
		} else if (this.state.action_dialog_message === 'Activate') {
			is_active = '1'
		}
		let Data = {
			users_subscribed_plans_id: plan_id,
			action_type: action,
			is_active: is_active
		}
		if (navigator.onLine) {
			this.setState({ spinner: 'block' })
			// console.log('posting data : ', Data)
			return fetch(URLHelper.SubscriptionPlanActions, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE")
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success', spinner: 'none' })
						this.gotToTab(this.state.account_id)
					}
					else {
						this.setState({ spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	inputHandler = (event, inputID) => {
		let value = event.target.value
		if (inputID === "policy_string") {
			this.setState({ policy_string: value })
		}
		if (inputID == "first_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_first_name: value })
			}
			else {
				//console.log("Not First Name")
			}
		}
		else if (inputID == "last_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_last_name: value })
			}
			else {
				//console.log("Not Last Name")
			}
		}
		else if (inputID == "address") {
			this.setState({ user_address: value })
		}
		else if (inputID == "city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ user_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		else if (inputID == "phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ user_phone: value })
				}
				else {
					//console.log("Not number")
				}
			}
		}
		else if (inputID == "email") {
			this.setState({ user_email: value })
		} else if (inputID == "name_on_card") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ name_on_card: value })
			}
		}
		else if (inputID == "card_number") {
			if (value.length < 17) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ card_number: value })
				}
			}
		}
		else if (inputID == "expiry_date") {
			this.setState({ expiry_date: value })
		}
		else if (inputID == "cvv") {
			if (value.length < 5) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ cvv: value })
				}
			}
		}

		else if (inputID == "card_address") {
			this.setState({ card_address: value })
		}
		else if (inputID == "card_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "card_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "card_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ card_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
	}

	handleBillingCheckbox = (event, inputField) => {
		// console.log('handleBillingCheckbox :',event.target.checked, inputField)
		if (inputField === 'is_billing_address') {
			this.setBillingAddress(event.target.checked)
		} else {
			this.setState({ [inputField]: event.target.checked })
		}
	}

	setBillingAddress = (action) => {
		if (action) {
			this.setState({
				is_billing_address: true, card_address: this.state.user_address,
				card_city: this.state.user_city, card_state: this.state.user_state, card_zip: this.state.user_zip
			})
		} else {
			this.setState({
				is_billing_address: false, card_address: '',
				card_city: '', card_state: '', card_zip: ''
			})
		}
	}

	formSubmit = (event) => {
		event.preventDefault()
		// console.log("submit called...")
		this.editUser()
	}

	billingFormSubmit = (event) => {
		event.preventDefault()
		console.log("called billingFormSubmit...")
		if ((this.state.name_on_card !== '' && this.state.card_number !== '' && this.state.expiry_date !== '' && this.state.cvv !== '' && this.state.card_address !== '' && this.state.card_city !== '' && this.state.card_state !== '' && this.state.card_zip !== '')) {
			if (this.state.is_agree_tnc) {
				this.addCreditCard()
			} else {
				this.setState({ snackMsg: 'Please read and accept our terms and conditions.', snack: true, snackVariant: 'error' })
			}
		} else {
			this.setState({ snackMsg: "Please fill out all the card details.", snack: true, snackVariant: 'error' })
		}
	}

	cancelSubmit = () => {
		// console.log("cancel called...")
		this.fetchCurrentUserDetails(this.state.account_id)
		this.setState({ input_field_disabled: true })
	}

	handleGroupChange = (event, value) => {
		// console.log('handleGroupChange')
		// console.log('event :', event)
		// console.log('value :', value)
		let policyString = '';
		let newGroups = [];
		let newSelectedIndex = [];
		// this.setState({ selected_index: [0] })
		if (value.length > 0) {
			value.forEach(element => {
				policyString += element.group_policy + ",";
				newGroups.push(element.group_policy_id);
			});
			policyString = policyString.slice(0, -1);
			if (event === 'init') {
				// console.log('handleGroupChange init time..')
				this.setState({ default_policy_string: policyString })
			}
			this.state.group_policies.forEach((policy, index) => {
				newGroups.forEach(key => {
					if (policy.group_policy_id == key) {
						newSelectedIndex.push(index)
					}
				})
			})
			this.setState({ selected_index: newSelectedIndex, policy_string: policyString, groups: newGroups });
		} else {
			this.setState({ selected_index: newSelectedIndex, default_policy_string: '', policy_string: '', groups: [] });
		}
		// console.log('policyString :',policyString)
	}

	async GetGroupPolicies() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block' })
			//console.log(Data)
			return fetch(URLHelper.GetGroupPolicies + "/" + 1, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ spinner: 'none' })
					if (res.status) {

						// console.log('TRUE :',res)
						let GroupPolicies = [];
						// let DefaultPolicies = [];
						let DefaultSelectedIndex = [];
						res.data.forEach((element, index) => {
							GroupPolicies.push({ group_policy_id: element.id, group_policy_name: element.group_policy_name, group_policy: element.group_policy });
							if (element.is_default === 1) {
								// DefaultPolicies.push({ group_policy_id: element.id, group_policy_name: element.group_policy_name, group_policy: element.group_policy });
								DefaultSelectedIndex.push(index);
							}
						});
						// this.handleGroupChange('init', DefaultPolicies )
						// console.log('GroupPolicies, DefaultPolicies :', GroupPolicies, DefaultPolicies)
						this.setState({ group_policies: GroupPolicies, default_selected_index: DefaultSelectedIndex, spinner: 'none' })

					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', spinner: 'none' })
					}
				})
				.catch((error) => {
					// console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	addCreditCard = () => {
		if (navigator.onLine) {
			this.setState({ billing_spinner: 'block' })
			let Data = {
				user_id: this.state.account_id,
				name: this.state.name_on_card,
				cc_num: this.state.card_number,
				exp_date: AppHelper.changeDateFormat(this.state.expiry_date),
				cvv: this.state.cvv,
				address: this.state.card_address,
				city: this.state.card_city,
				state: this.state.card_state,
				zip: this.state.card_zip,
				set_default_card: this.state.is_primary_card
			}
			return fetch(URLHelper.AddCreditCard, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ billing_spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE", res)
						this.resetBillingTab()
						this.fetchCardListing(this.state.account_id)
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success' })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	editUser = () => {
		if (navigator.onLine) {
			this.setState({ infoSpinner: 'block' })
			let Data = {
				admin_token: this.props.state.token,
				actionby_admin: this.props.state.user_type,
				user_id: this.state.account_id,
				first_name: this.state.user_first_name,
				last_name: this.state.user_last_name,
				phone_no: this.state.user_phone,
				address: this.state.user_address,
				city: this.state.user_city,
				state: this.state.user_state,
				zip: this.state.user_zip,
				email: this.state.user_email,
				policy_string: this.state.policy_string,
				groups: this.state.groups,
			}
			return fetch(URLHelper.EditUser, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ infoSpinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE")
						this.setState({
							snackMsg: res.message, snack: true, snackVariant: 'success',
							search: '', Page: 1, Accounts: [], hasMore: true, input_field_disabled: true
						})
						this.fetchUserData(1, '')
						this.fetchCurrentUserDetails(this.state.account_id)
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	callAction = (action_name, credit_card_id) => {
		let url = '';
		let Data = {
			admin_token: this.props.state.token,
			user_id: this.state.account_id,
			creditcard_id: credit_card_id
		}
		if (action_name == 'remove_credit_card') {
			url = URLHelper.RemoveCreditCard
		} else if (action_name == 'set_default_credit_card') {
			url = URLHelper.SetDefaultCreditCard
		}
		console.log('poting on url :', url)
		if (navigator.onLine) {
			this.setState({ cc_action_spinner: 'block' })
			// console.log('posting data : ', Data)
			return fetch(url, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ cc_action_spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE")
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success', })
						this.gotToTab(this.state.account_id)
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	hideSnackBar = () => {
		this.setState({ snack: false })
	}

	convertTime = (amount) => {
		// console.log('convertTime ::',amount)
		let givenSeconds = (amount / 0.15) * 60;
		// console.log('givenSeconds ::',givenSeconds)
		let dateObj = new Date(givenSeconds * 1000);
		let hours = dateObj.getUTCHours();
		let minutes = dateObj.getUTCMinutes();
		let seconds = dateObj.getSeconds();

		// console.log('(amount / 0.15) ::',(amount / 0.15))
		if ((amount / 0.15) > 120) {
			return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
			// return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') + ' Hours';
		} else {
			return (amount / 0.15).toFixed(0) + ' Minutes';
		}
	}

	render() {
		const { classes } = this.props;
		const minEndDate = moment(new Date(), "DD-MM-YYYY").add(1, 'days');
		if (this.props.state.token !== '') {
			return (
				<div className="App">
					{/* <Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar> */}
					<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />

					{/* <Header propsState={this.props.state} /> */}
					<AdminHeader propsState={this.props.state} />

					<div className="flex flex-wrap md:flex-no-wrap mt-6 w-11/12 mx-auto">
						<div className="container flex-initial w-full md:w-2/6 rounded-md border border-t-1 text-center bg-safeLightGray shadow-xl m-2">
							<Grid container className="px-4">
								<Grid item xs={12} sm={5} md={5}>
									<p className="tracking-normal float-left font-sans text-primary text-base font-semibold mt-6">Accounts</p>
								</Grid>
								<Grid item xs={12} sm={7} md={7} style={{ textAlign: 'end' }}>
									<TextField
										autoFocus={true}
										className="bg-white"
										id="search"
										name="search"
										label="Search"
										type="text"
										margin="normal"
										style={{ width: '100%' }}
										value={this.state.search}
										onChange={(event) => this.searchChange(event)}
										variant="outlined"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													{this.state.search != '' && <Search className="coursor-pointer" style={{ color: '#673ab7' }} onClick={() => this.searchAccount()} />}
												</InputAdornment>
											),
											autoComplete: 'new-search',
											form: {
												autoComplete: 'off',
											},
										}}
									/>
								</Grid>
							</Grid>
							<div id="account-list-scroll" className="mt-2 overflow-y-scroll rounded-b-lg bg-white flex w-full justify-center" style={{ height: '600px' }}>
								<InfiniteScroll
									dataLength={this.state.Accounts.length}
									next={this.fetchMoreData}
									hasMore={this.state.hasMore}
									// loader={<h4>Loading...</h4>}
									scrollableTarget="account-list-scroll">
									<ul className="flex flex-col py-4">
										{this.state.Accounts.length > 0 &&
											this.state.Accounts.map((Account, index) =>
												<li key={index} className="flex flex-row mb-1 text-xs py-1">
													{/* className="border" */}
													{/* <Paper elevation={0}> */}
													{/* className="transition bg-gray-200 duration-500 ease-in-out transform hover:-translate-y-1" */}
													<div className={clsx((Account.user_id === this.state.account_id) && 'active-account-row duration-600 ease-in transform', 'text-black select-none cursor-pointer rounded-md flex flex-1 items-center px-4 py-2')}>
														{/* <div className="select-none cursor-pointer rounded-md flex flex-1 items-center px-4"> */}
														<div className="flex-1 w-auto xl:w-24 mr-2 xl:mr-6">
															<div className="font-medium text-left"><b><p>{Account.name}</p></b></div>
															<div className="text-left"><p>{Account.contact}</p></div>
														</div>
														<div className="flex-1 w-auto xl:w-20 mr-2 xl:mr-6">
															<div className="font-medium text-left"><b><p>${Account.balance}</p></b></div>
															<div className="text-left"><b><p>{this.convertTime(Account.balance)}</p></b></div>
														</div>
														<div className="w-auto xl:w-32 pl-1 xl:pl-2">
															<ul className="flex flex-row py-4">
																<li>
																	<Tooltip title="Edit User" placement="top">
																		<button onClick={() => this.gotToTab(Account.user_id)} className="bg-primary hover:bg-gray-500 text-white py-1 px-1 xl:py-2 xl:px-2 rounded inline-flex items-center">
																			<img className="h-4" src={editPng} alt="edit-png" />
																		</button>
																	</Tooltip>
																</li>
																<li>
																	<Tooltip title="Update Minutes" placement="top">
																		<button onClick={() => { this.gotToTab(Account.user_id); this.handleUpdateMinutes(Account); }} className="bg-primary ml-1 hover:bg-gray-500 text-white py-1 px-1 xl:py-2 xl:px-2 rounded inline-flex items-center">
																			<img className="h-4" src={plusPng} alt="plus-png" />
																		</button>
																	</Tooltip>
																</li>
																<li>
																	<Tooltip title="Change Current Password" placement="top">
																		<button onClick={() => { this.gotToTab(Account.user_id); this.handleChangePasswordClickOpen(Account); }} className="bg-primary ml-1 hover:bg-gray-500 text-white py-1 px-1.5 xl:py-2 xl:px-2.5 rounded inline-flex items-center">
																			<img className="h-4" src={whiteLock} alt="white-lock" />
																		</button>
																	</Tooltip>
																</li>
															</ul>
														</div>
													</div>
													{/* </Paper> */}
												</li>
											)
										}
										<li>
											<div style={{ width: '100%', alignItems: 'center', display: this.state.accounts_spinner }}>
												<center><CircularProgress className={classes.progress} /></center>
											</div>
										</li>
									</ul>
								</InfiniteScroll>
							</div>
						</div>
						<div className="flex-initial w-full md:w-4/6 rounded text-center shadow-xl m-2">
							<nav className="p-4 rounded-t-md bg-safeBgGray">
								<div className="flex flex-wrap my-4 font-sans text-base">
									<button type="button" className={clsx((this.state.currentUserTab === 'customer_info') ? "text-black font-bold" : "text-gray-600", "mr-8 border-none border-transparent")}
										onClick={() => { this.setState({ currentUserTab: 'customer_info' }) }}>
										Customer info
									</button>
									<button type="button" className={clsx((this.state.currentUserTab === 'billing') ? "text-black font-bold" : "text-gray-600", "mr-8")}
										onClick={() => { this.setState({ currentUserTab: 'billing' }) }}>
										Billing
									</button>
									<button type="button" className={clsx((this.state.currentUserTab === 'logs') ? "text-black font-bold" : "text-gray-600", "mr-8")}
										onClick={() => { this.setState({ currentUserTab: 'logs' }) }}>
										Logs
									</button>
									<button type="button" className={clsx((this.state.currentUserTab === 'transactions') ? "text-black font-bold" : "text-gray-600", "mr-8")}
										onClick={() => { this.setState({ currentUserTab: 'transactions' }) }}>
										Transactions
									</button>
									<button type="button" className={clsx((this.state.currentUserTab === 'subscriptions') ? "text-black font-bold" : "text-gray-600")}
										onClick={() => { this.setState({ currentUserTab: 'subscriptions' }) }}>
										Subscriptions
									</button>
								</div>
							</nav>
							<div id="tab-list-scroll" className="rounded-md overflow-y-scroll" style={{ padding: '0px 20px', top: '0%', height: '600px' }}>
								<div className="flex flex-wrap z-10 sticky top-0 bg-white py-4">
									<button type="button" onClick={() => { (this.state.edit_button_disabled) ? this.setState({ snackMsg: "Please select an user to edit.", snack: true, snackVariant: 'error' }) : this.setState({ input_field_disabled: false, currentUserTab: 'customer_info' }) }} className="bg-primary mr-4 text-white px-1 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Edit Profile</button>
									<button type="button" onClick={() => { (this.state.account_id) === 0 ? this.setState({ snackMsg: "Please select an user to reset password.", snack: true, snackVariant: 'error' }) : this.handleClickOpen(this.state.account_id) }} className="bg-primary mr-4 text-white px-1 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Reset Password</button>

									<button type="button" onClick={() => { (this.state.account_id) === 0 ? this.setState({ snackMsg: "Please select an user to add time.", snack: true, snackVariant: 'error' }) : this.actionClick('refill', this.state.account_id) }} className="bg-primary mr-4 text-white px-1 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Add Time</button>
									<button type="button" onClick={() => { (this.state.account_id) === 0 ? this.setState({ snackMsg: "Please select an user for auto replenish.", snack: true, snackVariant: 'error' }) : this.actionClick('auto', this.state.account_id) }} className="bg-primary text-white px-1 mt-2 sm:mt-0 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Auto Replenish</button>
									{/* <button type="button" onClick={() => { (this.state.account_id) === 0 ? this.setState({ snackMsg: "Please select an user to add time.", snack: true, snackVariant: 'error' }) : this.props.history.push("/Plans", {PropsData: { type: 'refill', action_by: 'admin', user_id: this.state.account_id }} ) }} className="bg-primary mr-4 text-white px-1 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Add Time</button>
									<button type="button" onClick={() => { (this.state.account_id) === 0 ? this.setState({ snackMsg: "Please select an user for auto replenish.", snack: true, snackVariant: 'error' }) : this.props.history.push("/Plans", {PropsData: { type: 'auto-refill' }} ) }} className="bg-primary text-white px-1 md:px-6 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Auto Replenish</button> */}
									{(this.state.currentUserTab === 'logs') &&
										// {(this.state.userLogs.length > 0 && (this.state.currentUserTab === 'logs')) &&
										<div className="ml-4 mt-2 sm:mt-0 flex-grow">
											<FormControl className="w-full h-4">
												<InputLabel id="demo-simple-select-label">Filter Log Event</InputLabel>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={this.state.filterEvent}
													onChange={(event) => { (this.state.account_id === 0) ? this.setState({ snackMsg: "Please select an user to see logs.", snack: true, snackVariant: 'error' }) : this.handleChangeFilterEvent(event) }}
												>
													<MenuItem value={'all'}>All</MenuItem>
													<MenuItem value={'refill'}>Refill</MenuItem>
													<MenuItem value={'manual-refill'}>Manual Refill</MenuItem>
													<MenuItem value={'sign-in-out'}>Sign in/out</MenuItem>
													<MenuItem value={'deduction-logs'}>Deduction Logs</MenuItem>
													<MenuItem value={'print-logs'}>Printer Logs</MenuItem>
												</Select>
											</FormControl>
										</div>
									}
								</div>
								{(this.state.currentUserTab === 'customer_info') &&
									<div className="border-2 my-4 p-8 rounded">
										<form className={classes.containr} noValidate autoComplete="off" onSubmit={this.formSubmit}>
											<Grid container spacing={2} className="pb-4">
												<Grid item xs={12} sm={6} md={6}>
													<TextField
														autoFocus={true}
														id="first_name"
														label="First Name"
														type="text"
														className="w-full"
														disabled={this.state.input_field_disabled}
														value={this.state.user_first_name}
														onChange={(event) => this.inputHandler(event, "first_name")}
														variant="outlined"
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<TextField
														id="last_name"
														label="Last Name"
														type="text"
														className="w-full"
														disabled={this.state.input_field_disabled}
														value={this.state.user_last_name}
														onChange={(event) => this.inputHandler(event, "last_name")}
														variant="outlined"
													/>
												</Grid>
											</Grid>
											<TextField
												id="address"
												label="Address"
												type="text"
												disabled={this.state.input_field_disabled}
												className="w-full"
												value={this.state.user_address}
												onChange={(event) => this.inputHandler(event, "address")}
												variant="outlined"
											/>
											<div className="flex pt-4 pb-2">
												<div className="flex-initial w-2/5 pr-2 text-center">
													<TextField
														id="city"
														label="City"
														type="text"
														className="w-full"
														disabled={this.state.input_field_disabled}
														value={this.state.user_city}
														onChange={(event) => this.inputHandler(event, "city")}
														variant="outlined"
													/>
												</div>
												<div className="flex-initial w-1/5 pr-2 text-center">
													<TextField
														id="state"
														label="State"
														type="text"
														className="w-full"
														disabled={this.state.input_field_disabled}
														value={this.state.user_state}
														onChange={(event) => this.inputHandler(event, "state")}
														variant="outlined"
													/>
												</div>
												<div className="flex-initial w-2/5 text-center">
													<TextField
														id="zip"
														label="Zip"
														type="tel"
														className="w-full"
														disabled={this.state.input_field_disabled}
														value={this.state.user_zip}
														onChange={(event) => this.inputHandler(event, "zip")}
														variant="outlined"
													/>
												</div>
											</div>
											<Grid container spacing={2} className="py-2">
												<Grid item xs={12} sm={6} md={6} >
													<TextField
														id="phone"
														label="Phone #"
														type="tel"
														disabled={true}
														className="w-full"
														value={this.state.user_phone}
														onChange={(event) => this.inputHandler(event, "phone")}
														variant="outlined"
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={6} >
													<TextField
														id="email"
														label="Email"
														type="email"
														disabled={this.state.input_field_disabled}
														className="w-full"
														value={this.state.user_email}
														onChange={(event) => this.inputHandler(event, "email")}
														variant="outlined"
													/>
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												<Grid item xs={12} sm={6} md={6} >
													<TextField
														id="policies"
														label="Policies"
														disabled={this.state.input_field_disabled}
														className="w-full"
														value={this.state.policy_string}
														onChange={(event) => this.inputHandler(event, "policy_string")}
														variant="outlined"
													/>
												</Grid>
												{/* console.log('this.state.selected_index ::',this.state.selected_index) */}
												<Grid item xs={12} sm={6} md={6} >
													{
														// (!this.state.input_field_disabled) &&
														(this.state.group_policies.length > 0) &&
														<Autocomplete
															multiple
															style={{ width: '100%', paddingLeft: '2%', paddingRight: '2%' }}
															// disabled={true}
															disabled={this.state.input_field_disabled}
															options={this.state.group_policies}
															defaultValue={null}
															value={(this.state.selected_index) ? this.state.selected_index.map((number) => this.state.group_policies[number]) : null}
															getOptionLabel={option => option.group_policy_name}
															onChange={this.handleGroupChange}
															renderTags={(value) =>
																value.map((option, index) => (
																	<Chip
																		key={index}
																		className={classes.chip}
																		label={option.group_policy_name}
																		variant="outlined"
																	/>
																))
															}
															renderInput={params => (
																<TextField
																	{...params}
																	InputLabelProps={{ shrink: true }}
																	variant="standard"
																	label="Group Name"
																	placeholder="Groups"
																	fullWidth
																/>
															)}
														/>
													}
												</Grid>
											</Grid>
											<div style={{ width: '100%', alignItems: 'center', display: this.state.user_detail_spinner }}>
												<center><CircularProgress className={classes.progress} /></center>
											</div>
											{
												(this.state.user_detail_spinner === 'none') &&
												<div className="flex mt-2">
													{
														((this.state.is_set_auto_replenish === 1) || (this.state.auto_replenish_card_id > 0)) &&
														<>
															<div className="flex-initial text-safeFormGray text-center py-2">
																<p>Auto Replenish : </p>
															</div>
															<div className="flex-initial text-center px-4 py-2">
																{
																	(this.state.is_set_auto_replenish === 1) ?
																		(<Tooltip title="Disable" placement="top">
																			<ToggleOnTwoToneIcon style={{ color: '#32CD32' }} className="cursor-pointer" onClick={() => this.toggleAutoReplenish(this.state.account_id, '0')} />
																		</Tooltip>)
																		:
																		(<Tooltip title="Enable" placement="top">
																			<ToggleOffTwoToneIcon style={{ color: '#ff0000' }} className="cursor-pointer" onClick={() => this.toggleAutoReplenish(this.state.account_id, '1')} />
																		</Tooltip>)
																}
															</div>
														</>
													}
													{
														this.state.input_field_disabled !== true &&
														<div className="flex-grow text-center float-right">
															<div className="flex float-right">
																<button type="button" onClick={() => this.cancelSubmit()} className="text-safeRed px-1 md:px-6 font-sans font-semibold text-xs md:text-sm">Cancel</button>
																<button type="submit" disabled={((this.state.infoSpinner === 'block') || (this.state.account_id === 0))} className={"bg-primary w-32 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm"}>
																	{(this.state.infoSpinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Save Changes'}</button>
															</div>
														</div>
													}
												</div>
											}
										</form>
									</div>
								}
								{(this.state.currentUserTab === 'billing') &&
									<div className="border-2 my-4 p-8 rounded">
										<form className={classes.containr} noValidate autoComplete="off" onSubmit={this.billingFormSubmit}>
											<Grid container spacing={6} className="pb-4">
												<Grid item xs={12} sm={6} md={6}>
													<span className="float-left text-safeFormGray text-sm">Credit Card Info</span>
													<div>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	autoFocus={true}
																	id="name_on_card"
																	label="Name on card"
																	type="text"
																	className="w-full"
																	value={this.state.name_on_card}
																	onChange={(event) => this.inputHandler(event, "name_on_card")}
																	variant="outlined"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	id="card_number"
																	label="Card Number"
																	type="text"
																	className="w-full"
																	value={this.state.card_number}
																	onChange={(event) => this.inputHandler(event, "card_number")}
																	variant="outlined"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-initial w-1/2 text-center pr-2">
																		<MaskedInput
																			mask="11/11"
																			id="expiry_date"
																			name="expiry_date"
																			className="w-full pl-2 border-2 border-gray-300 text-black rounded"
																			style={{ height: '55px', fontSize: '16px' }}
																			value={this.state.expiry_date}
																			placeholder="Exp Date"
																			onChange={(event) => this.inputHandler(event, "expiry_date")}
																		/>
																	</div>
																	<div className="flex-initial w-1/2 text-center">
																		<TextField
																			type="tel"
																			id="cvv"
																			label="CVV"
																			className="w-full"
																			value={this.state.cvv}
																			onChange={(event) => this.inputHandler(event, "cvv")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
														</Grid>
													</div>
												</Grid>
												<Grid item xs={12} sm={6} md={6}>
													<span className="float-left text-safeFormGray text-sm">Billing Address</span>
													<div>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12}>
																<input
																	id="location"
																	placeholder="Search Address"
																	disabled={this.state.is_billing_address}
																	type="text"
																	margin="normal"
																	style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
																	ref={this.addressReference}
																	variant="outlined"
																	className="w-full text-black border-solid border border-gray-500 rounded"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	id="card_address"
																	label="Address"
																	type="text"
																	disabled={this.state.is_billing_address}
																	className="w-full"
																	value={this.state.card_address}
																	onChange={(event) => this.inputHandler(event, "card_address")}
																	variant="outlined"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-auto w-2/5 pr-2 text-center">
																		<TextField
																			id="card_city"
																			label="City"
																			type="text"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_city}
																			onChange={(event) => this.inputHandler(event, "card_city")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-auto w-1/5 pr-2 text-center">
																		<TextField
																			id="card_state"
																			label="State"
																			type="text"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_state}
																			onChange={(event) => this.inputHandler(event, "card_state")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-auto w-2/5 text-center">
																		<TextField
																			id="card_zip"
																			label="Zip"
																			type="tel"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_zip}
																			onChange={(event) => this.inputHandler(event, "card_zip")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="items-center">
																	<div className="text-center text-safeFormGray text-xs py-2 float-left">
																		<PrimaryCheckbox
																			size="small"
																			checked={this.state.is_billing_address}
																			onChange={(event) => { (this.state.edit_button_disabled) ? this.setState({ snackMsg: "You haven't selected any user!!", snack: true, snackVariant: 'error' }) : this.handleBillingCheckbox(event, 'is_billing_address') }}
																			inputProps={{ 'aria-label': 'billing address checkbox' }} />
																		Billing address same as customer info
																	</div>
																</div>
															</Grid>
														</Grid>
													</div>
												</Grid>
											</Grid>
											<div className="flex-wrap sm:flex mt-2 items-end">
												<div className="flex-1 text-safeFormGray text-xs">
													<Grid container>
														{/* <Grid item xs={12} sm={12} md={12}>
															<div className="float-left -mt-4">
																<PrimaryCheckbox
																size="small"
																checked={this.state.is_save_card_account}
																onChange={(event) => { this.handleBillingCheckbox(event, 'is_save_card_account') }}
																inputProps={{ 'aria-label': 'save card in account' }}/>
																Save card to account
															</div>
														</Grid> */}
														<Grid item xs={12} sm={12} md={12}>
															<div className="float-left -mt-4">
																<PrimaryCheckbox
																	size="small"
																	checked={this.state.is_primary_card}
																	onChange={(event) => { this.handleBillingCheckbox(event, 'is_primary_card') }}
																	inputProps={{ 'aria-label': 'set primary card' }} />
																Set as primary card
															</div>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<div className="float-left -mt-4">
																<PrimaryCheckbox
																	size="small"
																	checked={this.state.is_agree_tnc}
																	onChange={(event) => { this.handleBillingCheckbox(event, 'is_agree_tnc') }}
																	inputProps={{ 'aria-label': 't&c' }} />
																Agree to <span className="text-secondary cursor-pointer">terms and condition</span>
															</div>
														</Grid>
													</Grid>
												</div>
												<div className="flex-1">
													<div className="float-none sm:float-right">
														<button type="button" disabled={(this.state.spinner === 'block')} onClick={() => {
															this.resetBillingTab();
														}} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Back</button>
														<button type="submit" disabled={((this.state.spinner === 'block') || (this.state.account_id === 0))} className={clsx(((this.state.spinner === 'block') || (this.state.account_id === 0)) && "opacity-75 cursor-not-allowed", "bg-primary w-24 text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm")}>
															{(this.state.billing_spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Add Card'}</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								}
								{this.state.cardList.length > 0 && ((this.state.currentUserTab === 'billing') || (this.state.currentUserTab === 'customer_info')) &&
									<div className="my-6">
										<Dialog
											open={this.state.modalDPOpen}
											onClose={() => this.setState({ modalDPOpen: false })}
											aria-labelledby="alert-dialog-title"
											aria-describedby="alert-dialog-description">
											<DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
											<DialogContent>
												<DialogContentText id="alert-dialog-description">You want to remove this card?</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button onClick={() => this.setState({ modalDPOpen: false })} color="primary">
													No
													</Button>
												<Button onClick={() => {
													this.setState({ modalDPOpen: false })
													this.callAction('remove_credit_card', this.state.remove_credit_card_id)
												}} color="primary" autoFocus>
													Yes
													</Button>
											</DialogActions>
										</Dialog>
										{
											<div style={{ width: '100%', alignItems: 'center', display: this.state.cc_action_spinner }}>
												<center><CircularProgress className={classes.progress} /></center>
											</div>
										}
										{
											(this.state.cc_action_spinner === 'none') &&
											<TableContainer component={Paper}>
												<Table className="rounded" size="small" aria-label="cards table">
													<TableHead className="bg-primary rounded">
														<TableRow>
															<TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Credit Card</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Default</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Auto Replinish</span></TableCell>
															<TableCell></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.cardList.map(row => (
															<TableRow key={row.creditcard_id}>
																<TableCell component="th" scope="row">
																	<span className="pl-4">{row.last_four}</span>
																</TableCell>
																<TableCell>{(row.default_creditcard_id == row.creditcard_id) ? 'Yes' : 'No'}</TableCell>
																<TableCell>{(row.auto_replenish_creditcard_id == row.creditcard_id) ? 'Yes' : 'No'}</TableCell>
																<TableCell>
																	{(row.default_creditcard_id != row.creditcard_id) &&
																		<button
																			type="button"
																			onClick={() => this.setState({ modalDPOpen: true, remove_credit_card_id: row.creditcard_id })}
																			className="bg-primary mr-4 text-white px-1 md:px-2 py-1 shadow-xl rounded font-sans text-xs">
																			Remove
																	</button>
																	}
																	{(row.default_creditcard_id != row.creditcard_id) &&
																		<button
																			type="button"
																			onClick={() => this.callAction('set_default_credit_card', row.creditcard_id)}
																			className="bg-primary min-w-24 mt-2 m:mt-0 text-white px-0 md:px-2 py-1 shadow-xl rounded font-sans text-xs">
																			Set as Default
																	</button>
																	}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										}
									</div>
								}
								{(this.state.currentUserTab === 'logs') &&
									<div className="my-4">
										<InfiniteScroll
											dataLength={this.state.userLogs.length}
											next={this.fetchMoreLogData}
											hasMore={this.state.hasMoreLogData}
											loader={<h4>Loading...</h4>}
											scrollableTarget="tab-list-scroll"
										>
											<TableContainer component={Paper}>
												<Table className="rounded" size="small" aria-label="logs table">
													<TableHead className="bg-primary rounded">
														<TableRow>
															<TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Date</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Event</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Station</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Amount</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Running Minutes</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Running Balance</span></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.userLogs.length > 0 && this.state.userLogs.map((row, index) => (
															<TableRow key={index}>
																<TableCell component="th" scope="row">
																	{row.date}
																</TableCell>
																<TableCell>{row.event_name}</TableCell>
																<TableCell>{row.station}</TableCell>
																<TableCell style={{ color: (row.amount < 0) ? 'red' : 'blue' }}>
																	{row.amount < 0 ? '$' + row.amount * -1 : '$' + row.amount}
																</TableCell>
																<TableCell>{row.running_balance > 0 ? (row.running_balance / 0.15).toFixed(0) : 0}</TableCell>
																<TableCell style={{ color: (row.running_balance < 0) ? 'red' : 'black' }}>{'$' + (row.running_balance).toFixed(2)}</TableCell>
															</TableRow>
														))}
														{
															(this.state.logSpinner === 'block') &&
															<TableRow key='loader'>
																<TableCell colSpan={6}>
																	<div style={{ width: '100%', alignItems: 'center', display: this.state.logSpinner }}>
																		<center><CircularProgress className={classes.progress} /></center>
																	</div>
																</TableCell>
															</TableRow>
														}
													</TableBody>
												</Table>
											</TableContainer>
										</InfiniteScroll>
									</div>
								}
								{(this.state.currentUserTab === 'transactions') &&
									<div className="my-4">
										<InfiniteScroll
											dataLength={this.state.billingList.length}
											next={this.fetchMoreBillingData}
											hasMore={this.state.hasMoreBillingData}
											loader={<h4>Loading...</h4>}
											scrollableTarget="tab-list-scroll"
										>
											<TableContainer component={Paper}>
												<Table className="rounded" size="small" aria-label="logs table">
													<TableHead className="bg-primary rounded">
														<TableRow>
															<TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Date</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Method</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Amount</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Plan</span></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.billingList.length > 0 && this.state.billingList.map(row => (
															<TableRow key={row.transaction_id}>
																<TableCell component="th" scope="row">
																	{row.date_created}
																</TableCell>
																<TableCell>{row.method}</TableCell>
																<TableCell>{row.actual_amount_paid}</TableCell>
																<TableCell>{row.plan_id}</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
										</InfiniteScroll>
									</div>
								}
								{(this.state.currentUserTab === 'subscriptions') &&
									<>
										<div className="border-2 my-4 p-8 rounded">
											<Grid container spacing={2}>
												<Grid item xs={12} md={6} sm={6}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DatePicker
															autoOk
															invalidDateMessage='Invalid Date Format'
															disablePast
															style={{ marginBottom: '2%', marginRight: '2%' }}
															value={this.state.plan_start_date}
															placeholder="Plan start date"
															label="Plan start date"
															onChange={(date) => this.handleDateChange('start', date)}
															minDate={new Date()}
															format="dd/MM/yyyy"
															inputVariant="outlined"
															fullWidth
														/>
													</MuiPickersUtilsProvider>
												</Grid>

												<Grid item xs={12} md={6} sm={6}>
													<MuiPickersUtilsProvider utils={DateFnsUtils}>
														<DatePicker
															autoOk
															invalidDateMessage='Invalid Date Format'
															disablePast
															style={{ marginBottom: '2%', marginLeft: '2%' }}
															value={this.state.plan_end_date}
															placeholder="Plan end date"
															label="Plan end date"
															onChange={(date) => this.handleDateChange('end', date)}
															minDate={minEndDate}
															format="dd/MM/yyyy"
															inputVariant="outlined"
															fullWidth
														/>
													</MuiPickersUtilsProvider>
												</Grid>
											</Grid>
											<Grid container spacing={2}>
												<Grid item xs={12} md={6} sm={6}>
													<Autocomplete
														className="w-full"
														options={this.state.subscription_plans}
														getOptionLabel={option => option.plan_name + ' - ' + option.plan_price + '$'}
														onChange={this.handleSubscriptionPlanChange}
														defaultValue={null}
														value={(this.state.current_selected_plan_index !== -1) ? this.state.subscription_plans[this.state.current_selected_plan_index] : null}
														// value={(this.state.selected_index) ? this.state.selected_index.map((number) => this.state.group_policies[number]) : null}
														renderInput={params => (
															<TextField
																{...params}
																InputLabelProps={{ shrink: true }}
																label="Subscription Plans"
																placeholder="Select Subscription Plans"
																variant="outlined"
																fullWidth
															/>
														)}
													/>
												</Grid>

												<Grid item xs={12} md={6} sm={6}>
													<button
														type="button"
														disabled={(this.state.subscription_spinner === 'block')}
														className="bg-primary text-white px-2 md:px-4 py-2 m-2 shadow-xl rounded font-sans text-xs md:text-sm float-left"
														onClick={() => { (this.state.account_id > 0) ? this.saveUserSubscriptionPlan() : this.setState({ snackMsg: "Please select an user to add plan.", snack: true, snackVariant: 'error' }) }}>
														{(this.state.subscription_spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Add'}
													</button>
												</Grid>
											</Grid>
										</div>
										<div className="my-6">
											<Dialog
												open={this.state.modelConfirmDelete}
												onClose={() => this.setState({ modelConfirmDelete: false })}
												aria-labelledby="confirm-dialog-title"
												aria-describedby="confirm-dialog-description">
												<DialogTitle id="confirm-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
												<DialogContent>
													<DialogContentText id="confirm-dialog-description">You want to {this.state.action_dialog_message} this plan?</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button onClick={() => this.setState({ modelConfirmDelete: false })} color="primary">
														No
													</Button>
													<Button onClick={() => {
														this.setState({ modelConfirmDelete: false })
														this.SubscriptionPlanAction(this.state.subscription_plan_action, this.state.action_subscription_plan_id)
													}} color="primary" autoFocus>
														Yes
													</Button>
												</DialogActions>
											</Dialog>
											<TableContainer component={Paper}>
												<Table className="rounded" size="small" aria-label="cards table">
													<TableHead className="bg-primary rounded">
														<TableRow>
															<TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Sr No.</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Plan Name</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Plan Amount $</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Start Date</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">End Date</span></TableCell>
															<TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Action</span></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{this.state.userSubcribedPlans.length > 0 && this.state.userSubcribedPlans.map((row, index) => (
															<TableRow key={index}>
																<TableCell component="th" scope="row">
																	{index + 1}
																</TableCell>
																<TableCell>{row.plan_name}</TableCell>
																<TableCell>{row.amount}</TableCell>
																<TableCell>{moment(row.start_date).format('DD-MM-YYYY')}</TableCell>
																<TableCell>{moment(row.end_date).format('DD-MM-YYYY')}</TableCell>
																<TableCell>
																	<Tooltip title={(row.is_active === 1) ? "Deactivate" : "Activate"} placement="top">
																		<GreenSwitch
																			checked={(row.is_active === 1) ? true : false}
																			onChange={() => this.setState({ action_subscription_plan_id: row.users_subscribed_plans_id, modelConfirmDelete: true, subscription_plan_action: 'status', action_dialog_message: (row.is_active === 1) ? 'Deactivate' : 'Activate' })}
																			value={row.is_active}
																		/>
																	</Tooltip>
																	<Tooltip title="Delete" placement="top">
																		<Button
																			onClick={() => this.setState({ action_subscription_plan_id: row.users_subscribed_plans_id, modelConfirmDelete: true, subscription_plan_action: 'delete', action_dialog_message: 'Delete' })}
																			style={{ textTransform: 'none', fontSize: '16px', width: 'auto' }}>
																			<DeleteIcon style={{ color: '#673ab7' }} />
																		</Button>
																	</Tooltip>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
											<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
												<center><CircularProgress className={classes.progress} /></center>
											</div>
										</div>
									</>
								}
							</div>
						</div>
					</div>

					<Grid container>
						<Dialog maxWidth={'xs'} fullWidth={false} open={this.state.messageDialog} onClose={this.handleCloseMessageDialog} aria-labelledby="form-dialog-title">
							<DialogContent>
								<div className="font-semibold w-64 mx-auto">
									<p className="text-primary text-center w-full">A temporary password was sent to the customer.</p>
									<p className="text-primary text-xs text-center px-2">Customer should use that code to login and reset their password.</p>
								</div>
							</DialogContent>
							<DialogActions>
								<Grid container>
									<div className="flex flex-col w-full">
										<div className="text-center">
											<button
												type="button"
												className="bg-primary w-24 text-white px-4 py-3 my-2 shadow-xl rounded text-base"
												onClick={() => {
													this.handleCloseMessageDialog();
												}}>Done
											</button>
										</div>
									</div>
								</Grid>
							</DialogActions>
						</Dialog>
						<Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title"><p className="text-primary text-center">Create Temp Password</p></DialogTitle>
							<DialogContent>
								<TextField
									autoFocus={true}
									id="password"
									label="Temp Password"
									type="password"
									className="w-full"
									value={this.state.temp_password}
									onChange={(event) => this.setState({ temp_password: event.target.value })}
									inputProps={{
										autoComplete: 'new-password',
										form: {
											autoComplete: 'off',
										},
									}}
									variant="outlined"
								/>
								<TextField
									autoFocus={false}
									id="confirm_temp_password"
									label="Confirm Temp Password"
									type="password"
									margin="normal"
									className="w-full"
									value={this.state.confirm_temp_password}
									onChange={(event) => this.setState({ confirm_temp_password: event.target.value })}
									variant="outlined"
								/>
							</DialogContent>
							<DialogActions>
								{this.state.BUTTONS &&
									<Grid container>
										<div className="flex flex-col w-full mx-4">
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
													onClick={() => this.getTempPassword()}>
													{(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Create Temp Password'}
												</button>
											</div>
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
													onClick={() => {
														this.handleClose();
													}}>Back
												</button>
											</div>
										</div>
									</Grid>
								}
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress className={classes.progress} /></center>
								</div>
							</DialogActions>
						</Dialog>

						<Dialog open={this.state.modalCPOpen} onClose={this.handleChangePasswordClose} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title"><p className="text-primary text-center">Change Current Password</p></DialogTitle>
							<DialogContent>
								<TextField
									autoFocus={true}
									id="change_password"
									label="Change Password"
									type="password"
									className="w-full"
									value={this.state.change_password}
									onChange={(event) => this.setState({ change_password: event.target.value })}
									variant="outlined"
									inputProps={{
										autoComplete: 'new-password',
										form: {
											autoComplete: 'off',
										},
									}}
								/>
								<TextField
									autoFocus={false}
									id="confirm_change_password"
									label="Confirm Change Password"
									type="password"
									margin="normal"
									className="w-full"
									value={this.state.confirm_change_password}
									onChange={(event) => this.setState({ confirm_change_password: event.target.value })}
									variant="outlined"
								/>
							</DialogContent>
							<DialogActions>
								{this.state.BUTTONS &&
									<Grid container>
										<div className="flex flex-col w-full mx-4">
											{/* opacity-50 cursor-not-allowed */}
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
													onClick={() => this.getChangeCurrentPassword()}>
													{(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Change Current Password'}
												</button>
											</div>
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
													onClick={() => {
														this.handleChangePasswordClose();
													}}>Back
												</button>
											</div>
										</div>
									</Grid>
								}
							</DialogActions>
						</Dialog>

						<Dialog open={this.state.modalUBOpen} onClose={this.handleUpdateMinutesDialogClose} aria-labelledby="form-dialog-title">
							<DialogTitle id="form-dialog-title"><p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Update Minutes</p></DialogTitle>
							<DialogContent>
								<TextField
									autoFocus={true}
									id="new_minutes"
									label="Minutes"
									type="number"
									className="w-full"
									value={this.state.new_minutes}
									onChange={(event) => this.setState({ new_minutes: event.target.value })}
									// onKeyDown={(event) => {
									// 	if(event.key === 'Enter'){
									// 		this.updateCurrentBalance()
									// 	}
									// }}
									variant="outlined"
								/>
							</DialogContent>
							<DialogActions>
								{this.state.BUTTONS &&
									<Grid container>
										<div className="flex flex-col w-full mx-4">
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
													onClick={() => this.updateCurrentBalance()}>
													{(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Update'}
												</button>
											</div>
											<div className="text-center">
												<button
													type="button"
													disabled={(this.state.spinner === 'block')}
													className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
													onClick={() => {
														this.handleUpdateMinutesDialogClose();
													}}>Back
												</button>
											</div>
										</div>
									</Grid>
								}
							</DialogActions>
						</Dialog>
					</Grid>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

AccountList.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit, address, city, state, zip) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
				address: address,
				city: city,
				state: state,
				zip: zip,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountList));
