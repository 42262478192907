import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import jwt_decode from 'jwt-decode'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '0px 10px',
        //margin: '10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
});

class SetNewPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            password: '',
            confirm_password: '',
            snackMsg: '',
            snack: false,
            spinner: 'none',
            BUTTONS: true
        }
    }

    componentWillMount() {
        if (this.props.state.token === '') {
            window.location.href = "/"
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();

        if (this.state.password == '') {
            this.setState({ snackMsg: "Password is Required.", snack: true })
        }
        else if (this.state.confirm_password == '') {
            this.setState({ snackMsg: "Confirm Password is Required.", snack: true })
        }
        else if (this.state.password !== this.state.confirm_password) {
            this.setState({ snackMsg: "Confirm Password isn't matched!!.", snack: true })
        }
        else {
            this.ChangePassword()
        }
    }

    async ChangePassword() {
        if (navigator.onLine) {
            var Data = {
                user_id: this.props.state.user_id,
                password: this.state.password,
                confirm_change_password : 1
            }
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.SetNewPassword, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    if (res.status) {
                        console.log(res)
                        var token = res.data.token
                        var user_id = jwt_decode(token).user_id
                        var type = res.data.role
                        var login_status = res.data.login_status
                        this.props.LoginSuccess(token, user_id, type)
                        if(login_status === 0){
                            if (type === 'Admin') {
                                this.props.history.push("/AccountList")
                            }
                            else {
                                this.props.history.push("/MainMenu")
                            }
                        }
                        else{
                            window.location.href="/SetNewPassword"
                        }
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
                    }
                })
                .catch((error) => {
                    //console.log("Error==>" + error)
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className="App">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.snack}
                    autoHideDuration={1500}
                    onClose={() => this.setState({ snack: false })}>
                    <SnackbarContent
                        className="error"
                        aria-describedby="client-snackbar"
                        message={this.state.snackMsg}
                    />
                </Snackbar>
                <Grid container>
                    <Grid item xs />
                    <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                        <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '30px 20px' }}>
                            <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                                <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Reset Password</p>
                                <TextField
                                    autoFocus={true}
                                    id="password"
                                    label="Password"
                                    className={classes.Margin}
                                    type="password"
                                    margin="normal"
                                    style={{ width: '100%' }}
                                    value={this.state.password}
                                    onChange={(event) => this.setState({ password: event.target.value })}
                                    variant="outlined"
                                />
                                <TextField
                                    autoFocus={false}
                                    id="confirm_password"
                                    label="Confirm Password"
                                    className={classes.Margin}
                                    type="password"
                                    margin="normal"
                                    style={{ width: '100%' }}
                                    value={this.state.confirm_password}
                                    onChange={(event) => this.setState({ confirm_password: event.target.value })}
                                    variant="outlined"
                                />
                                {this.state.BUTTONS &&
                                    <Grid container>
                                        <Button type="submit" variant="contained" style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Sign In
                                        </Button>
                                        <Button variant="contained" onClick={() => this.props.Logout()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10, fontSize: 16 }}>
                                            Back
                                        </Button>
                                    </Grid>}
                                <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                    <center><CircularProgress className={classes.progress} /></center>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs />

                </Grid>
            </div>
        );
    }
}

SetNewPassword.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        LoginSuccess: (token, user_id, type) => {
            var value = {
                token: token,
                user_id: user_id,
                user_type: type
            }
            dispatch({ type: 'CustomerLoginSuccess', value: value })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SetNewPassword));
