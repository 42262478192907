import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid, Button, CircularProgress, Snackbar, SnackbarContent, TextField, InputAdornment, Tooltip,
	Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Table, TableBody, TableCell,
	TableHead, TableRow, Chip, Switch, FormControl, Select, InputLabel, MenuItem, Divider, Paper, TableContainer, Checkbox
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import URLHelper from '../Helper/URLHelper';
import { connect } from 'react-redux';
import { Search } from '@material-ui/icons';
import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';
import InfiniteScroll from "react-infinite-scroll-component";
import { fail } from 'assert';
import AppHelper from '../Helper/AppHelper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaskedInput from 'react-maskedinput';
import successWhitePng from '../assets/success_white.png';
import successPng from '../assets/success.png';
import AdminHeader from './AdminHeader';
import progressPng from '../assets/progress.png';
import clsx from 'clsx';
import "react-tabs/style/react-tabs.css";
import { arrayIncludes } from '@material-ui/pickers/_helpers/utils';
import SafeTechSnackbar from './SafeTechSnackbar';

const PrimaryCheckbox = withStyles({
	root: {
	  '&$checked': {
		color: '#273359',
	  },
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

class AddNewAccount extends Component {

	constructor(props) {
		console.log('AddNewAccount component :', props)
		super(props)
		this.state = {
			first_name: '',
			credit: '',
			spinner: 'none',
			plans_spinner: 'none',
			infoSpinner: 'none',
			logSpinner: 'none',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			BUTTONS: false,
			Page: 1,
			billingPage: 1,
			logPage: 1,
			limit: 20,
			hasMore: true,
			hasMoreBillingData: false,
			hasMoreLogData: false,
			// hasMore: false,
			search: '',
			DisplayAccounts: [],
			modalOpen: false,
			modalCPOpen: false,
			modalUBOpen: false,
			modalDPOpen: false,
			setUserIdToChangePassword: null,
			password: '',
			confirm_password: '',
			account_id: 0,
			user_first_name: '',
			user_last_name: '',
			user_street_number: '',
			user_address: '',
			user_city: '',
			user_state: '',
			user_zip: '',
			user_phone: '',
			user_email: '',
			card_phone: '',
			card_email: '',
			is_set_auto_replenish: '',
			auto_replenish_card_id: '',
			input_field_disabled: true,
			edit_button_disabled: true,
			remove_credit_card_id: 0,
			group_policies: [],
			default_policy_string: '',
			groups: [],
			default_selected_index: [],
			selected_index: [],
			policy_string: '',
			current_selected_plan_id: 0,
			current_selected_plan_amount: 0,
			plan_start_date: moment(new Date(), "DD-MM-YYYY"),
			plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
			modelConfirmDelete: false,
			subscription_plan_action: '',
			action_dialog_message: '',
			action_subscription_plan_id: 0,
			subscription_plans: [],
			filterEvent: 'all',
			currentUserTab: 'customer_info',
			is_billing_address: false,
			card_street_number: '',
            card_address: '',
			card_city: '',
			card_state: '',
			card_zip: '',
			is_save_card_account: false,
			is_primary_card: false,
			is_agree_tnc: false,
			name_on_card: '',
			card_number: '',
			expiry_date: '',
			cvv: '',
			selected_account_plan: '',
			plans: [],
		}
		this.addressReference = React.createRef();
		this.cardAddressReference = React.createRef();
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ plans_spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ plans_spinner: 'none', BUTTONS: true })
					if (res.status) {
						let planList = [];
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
								planList.push(element)
							})
						}
						this.setState({ plans: planList })
					}
					else {
						this.setState({ plans: [], snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ plans_spinner: 'none', plans: [], snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	componentDidMount() {
		if (this.props.state.token === '' || this.props.state.user_type === 'User') {
			window.location.href = "/"
		}else{
			this.fetchPlanListing();
			const autocompleteFormField = this.addressReference.current;
			const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
				types: ['address'],
			});
			window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
				this.setState({ user_street_number: '', user_address: '' , user_zip: '', user_state: '', user_city: '' })
				const place = autocomplete.getPlace();
				// console.log('PLACE ===>', place);
				if (place.address_components != null) {
					place.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ user_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setState({ user_address: this.state.user_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setState({ user_zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setState({ user_state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setState({ user_city: value.long_name })
						}
					})
				}
			});

			const cardAutocompleteFormField = this.cardAddressReference.current;
			const cardAutocomplete = new window.google.maps.places.Autocomplete((cardAutocompleteFormField), {
				types: ['address'],
			});
			window.google.maps.event.addListener(cardAutocomplete, 'place_changed', () => {
				this.setState({ card_street_number: '', card_address: '', card_zip: '', card_state: '', card_city: '' })
				const cardPlace = cardAutocomplete.getPlace();
				// console.log('CARD PLACE ===>', cardPlace);
				if (cardPlace.address_components != null) {
					cardPlace.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ card_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setState({ card_zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setState({ card_state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setState({ card_city: value.long_name })
						}
					})
				}
			});
		}
	}

	isValidUserAddress = () => {
		// if(this.state.user_address !== '' && this.state.user_city !== '' && this.state.user_state !== '' && this.state.user_zip !== ''){
		if(this.state.user_address !== '' && this.state.user_city !== '' && this.state.user_state !== '' && this.state.user_zip !== ''
		&& this.state.user_phone !== '' && this.state.user_email !== ''){
			return true;
		}else{
			return false;
		}
	}

	setCardAddressAsUserAddress = () => {
		this.setState({ is_billing_address: true,
			card_address: this.state.user_address,
			card_city: this.state.user_city,
			card_state: this.state.user_state,
			card_zip: this.state.user_zip,
			card_phone: this.state.user_phone,
			card_email: this.state.user_email
		});
	}

	resetCardAddress = () => {
		this.setState({ is_billing_address: false, card_address: '', card_city: '', card_state: '', card_zip: '', card_phone: '', card_email: '' });
	}

	inputHandler = (event, inputID) => {
		let value = event.target.value
		if (inputID === "policy_string") {
			this.setState({ policy_string: value })
		}
		if (inputID == "first_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_first_name: value })
			}
			else {
				//console.log("Not First Name")
			}
		}
		else if (inputID == "last_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_last_name: value })
			}
			else {
				//console.log("Not Last Name")
			}
		}
		else if (inputID == "user_address") {
			this.setState({ user_address: value })
		}
		else if (inputID == "card_address") {
			this.setState({ card_address: value })
		}
		else if (inputID == "user_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "card_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "user_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ user_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "card_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "user_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ user_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		else if (inputID == "card_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ card_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		else if (inputID == "phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ user_phone: value })
				}
				else {
					//console.log("Not number")
				}
			}
		}
		else if (inputID == "card_phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ card_phone: value })
				}
				else {
					//console.log("Not number")
				}
			}
		}
		else if (inputID == "email") {
			this.setState({ user_email: value })
		}
		else if (inputID == "card_email") {
			this.setState({ card_email: value })
		}
		else if (inputID == "name_on_card") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ name_on_card: value })
            }
        }
        else if (inputID == "card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ card_number: value })
                }
            }
        }
        else if (inputID == "expiry_date") {
            this.setState({ expiry_date: value })
        }
        else if (inputID == "cvv") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ cvv: value })
                }
            }
        }
        else if (inputID == "password") {
            this.setState({ password: value })
        }
        else if (inputID == "confirm_password") {
			this.setState({ confirm_password: value })
        }
	}

	handleBillingCheckbox = (event, inputField) => {
		// console.log('handleBillingCheckbox :',event.target.checked, inputField)
		if(inputField === 'is_billing_address'){
			this.setBillingAddress(event.target.checked)
		}else{
			this.setState({ [inputField]: event.target.checked })
		}
	}

	setBillingAddress = (action) => {
		if(action){
			this.setState({ is_billing_address: true, card_address: this.state.user_address,
                card_city: this.state.user_city, card_state: this.state.user_state, card_zip: this.state.user_zip })
		}else{
			this.setState({ is_billing_address: false, card_address: '',
                card_city: '', card_state: '', card_zip: '' })
		}
	}

	formSubmit = (event) => {
		event.preventDefault()
		console.log("submit called...")
		this.addNewUser()
	}

	cancelSubmit = () => {
		console.log("cancel called...")
		this.resetCardAddress();
		this.setState({ user_first_name: '', user_last_name: '', user_phone: '', user_address: '', user_city: '',
		user_state: '', user_zip: '', user_email: '', password: '', confirm_password: '', selected_account_plan: '', name_on_card: '', card_number: '',
		expiry_date: '',cvv: '' })
	}

	addNewUser = () => {
		let msg = '';
		if(this.state.password === ''){
			msg += "Password is required";
		}
		else if(this.state.confirm_password === ''){
			msg += "Confirm password is required";
		}
		if(this.state.password !== '' && this.state.confirm_password !== ''){
			if(this.state.password !== this.state.confirm_password){
				msg += "Password and Confirm Password Not Matched";
			}
		}
		if(msg !== ''){
			this.setState({ snackMsg: msg, snack: true, snackVariant: 'error' });
		}
		else{
			if (navigator.onLine) {
				this.setState({ spinner: 'block' })
				let Data = {
					admin_token: this.props.state.token,
					first_name: this.state.user_first_name,
					last_name: this.state.user_last_name,
					phone_no: this.state.user_phone,
					address: this.state.user_address,
					city: this.state.user_city,
					state: this.state.user_state,
					zip: this.state.user_zip,
					email: this.state.user_email,
					password: this.state.password,
					plan_id: this.state.selected_account_plan,
					name_on_card: this.state.name_on_card,
					cc_num: this.state.card_number,
					exp_date: AppHelper.changeDateFormat(this.state.expiry_date),
					cvv: this.state.cvv,
					billing_address: this.state.card_address,
					billing_city: this.state.card_city,
					billing_state: this.state.card_state,
					billing_zip: this.state.card_zip,
					billing_phone: this.state.card_phone,
					billing_email: this.state.card_email,
				}
				return fetch(URLHelper.AddNewAccount, {
					method: 'POST',
					headers: {
						'Accept': 'Application/json',
						'Content-Type': 'application/json',
						'authorization': 'Bearer ' + this.props.state.token
					},
					body: JSON.stringify(Data),
				}).then((Response) => Response.json())
					.then((res) => {
						this.setState({ spinner: 'none' })
						// console.log("Response==>\n" + JSON.stringify(res))
						if (res.status) {
							// console.log("TRUE ====>")
							this.setState({ modalOpen: true, spinner: 'none'}, () => {
								this.cancelSubmit();
							})
							// this.fetchUserData(1, '')
						}
						else {
							this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', spinner: 'none' })
						}
					})
					.catch((error) => {
						console.log("Error==>" + error)
						this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none' })
					})
			}
			else {
				this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
			}
		}
	}

    listItems = (plansArray) => {
		// console.log('plans ::',plansArray)
        return (
		plansArray.map((data, index) =>
			<div key={data.plan_id} className="p-2 w-6/12 lg:w-4/12 xl:w-3/12">
				<div className="border-2 rounded py-2 my-2">
					{
						(data.bonus > 0) &&
						<div className="-mt-6 -mr-32 md:-mr-40 lg:-mr-32 xl:-mr-40">
							<button type="button"
								className="bg-safeYellow h-10 w-10 p-1 text-white rounded-lg font-sans text-xs">
								Save <span className="text-smXs">{(data.bonus * 100 / data.price) % 1 != 0 ? (data.bonus * 100 / data.price).toFixed(2) : (data.bonus * 100 / data.price)}%</span>
							</button>
						</div>
					}
					<div className={clsx((data.bonus > 0) && '-mt-4', 'flex flex-col items-center')}>
						<span className="py-1 md:py-2">{this.convertTime(data.price + data.bonus)}</span>
						{/* <span className="py-1 md:py-2">{((data.price + data.bonus) / 0.15).toFixed(0)} Minutes</span> */}
						{
							(this.state.selected_account_plan !== '' && this.state.selected_account_plan === data.plan_id) ?
							<button onClick={() => this.setState({selected_account_plan: ''})} type="button" className="bg-safeGreen w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
								<div className="flex flex-row items-center">
									<img className="h-4" src={successWhitePng} alt="success-white-png"/>
									<span className="pl-2">Selected</span>
								</div>
							</button>
							:
							<button onClick={() => this.setState({selected_account_plan: data.plan_id})} type="button" className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
								<span className="text-xs">$ </span>{ data.price.toFixed(2) }
							</button>
						}
					</div>
				</div>
			</div>
		));
	}
	
	handleClose = () => {
		this.setState({ modalOpen: false})
	}

    hideSnackBar = () => {
		this.setState({ snack: false })
	}

	convertTime = (amount) => {
        // console.log('convertTime ::',amount)
        let givenSeconds = (amount / 0.15) * 60;
        // console.log('givenSeconds ::',givenSeconds)
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        if((amount / 0.15) > 120){
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
        }else{
            return (amount / 0.15).toFixed(0) + ' Minutes'; 
        }
    }

	render() {
		const { classes } = this.props;
		const minEndDate = moment(new Date(), "DD-MM-YYYY").add(1, 'days');
		if (this.props.state.token !== '') {
			return (
				<div className="App">
					{/* <Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar> */}
					<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />

					<AdminHeader propsState={this.props.state} />
					
					<div className="my-12 w-11/12 sm:w-9/12 rounded mx-auto">
                        <Paper elevation={3}>
                            <div className="flex-initial w-full rounded text-center shadow-xl py-4 px-2 sm:px-24">
                                <div className="rounded-md">

                                    <div className="flex pt-4 pb-2">
                                        <span className="float-left text-primary text-sm">Add New Account</span>
                                    </div>

                                    <form noValidate autoComplete="off" onSubmit={this.formSubmit}>
                                    
                                        <div className="border-2 my-4 py-4 px-8 rounded">
                                            <span className="float-left my-2 text-safeFormGray text-sm">Customer Info</span>
                                            <Grid container spacing={2} className="pb-4">
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        autoFocus={true}
                                                        id="first_name"
                                                        label="First Name"
                                                        type="text"
                                                        className="w-full"
                                                        value={this.state.user_first_name}
                                                        onChange={(event) => this.inputHandler(event, "first_name")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        id="last_name"
                                                        label="Last Name"
                                                        type="text"
                                                        className="w-full"
                                                        value={this.state.user_last_name}
                                                        onChange={(event) => this.inputHandler(event, "last_name")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
												<Grid item xs={12} sm={12} md={12}>
													<input
														id="location"
														placeholder="Search Address"
														type="text"
														margin="normal"
														style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
														ref={this.addressReference}
														variant="outlined"
														className="w-full text-black border-solid border border-gray-500 rounded"
													/>
												</Grid>
                                            </Grid>
                                            <TextField
                                                id="user_address"
                                                label="Address"
                                                type="text"
                                                className="w-full"
                                                value={this.state.user_address}
                                                onChange={(event) => this.inputHandler(event, "user_address")}
                                                variant="outlined"
                                            />
                                            <div className="flex pt-4 pb-2">
                                                <div className="flex-initial w-2/5 pr-2 text-center">
                                                    <TextField
                                                        id="user_city"
                                                        label="City"
                                                        type="text"
                                                        className="w-full"
                                                        value={this.state.user_city}
                                                        onChange={(event) => this.inputHandler(event, "user_city")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="flex-initial w-1/5 pr-2 text-center">
                                                    <TextField
                                                        id="user_state"
                                                        label="State"
                                                        type="text"
                                                        className="w-full"
                                                        value={this.state.user_state}
                                                        onChange={(event) => this.inputHandler(event, "user_state")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="flex-initial w-2/5 text-gray-700 text-center">
                                                    <TextField
                                                        id="user_zip"
                                                        label="Zip"
                                                        type="tel"
                                                        className="w-full"
                                                        value={this.state.user_zip}
                                                        onChange={(event) => this.inputHandler(event, "user_zip")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div>
                                            <Grid container spacing={2} className="py-2">
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="phone"
                                                        label="Phone #"
                                                        type="tel"
                                                        className="w-full"
                                                        value={this.state.user_phone}
                                                        onChange={(event) => this.inputHandler(event, "phone")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="email"
                                                        label="Email"
                                                        type="email"
                                                        className="w-full"
                                                        value={this.state.user_email}
                                                        onChange={(event) => this.inputHandler(event, "email")}
														variant="outlined"
														inputProps={{
															autoComplete: 'new-email',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="password"
                                                        label="Temp Password"
                                                        type="password"
                                                        className="w-full"
                                                        value={this.state.password}
                                                        onChange={(event) => this.inputHandler(event, "password")}
														variant="outlined"
														inputProps={{
															autoComplete: 'new-password',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="confirm_password"
                                                        label="Confirm Temp Password"
                                                        type="password"
                                                        className="w-full"
                                                        value={this.state.confirm_password}
                                                        onChange={(event) => this.inputHandler(event, "confirm_password")}
														variant="outlined"
														inputProps={{
															autoComplete: 'confirm-password',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className="border-2 my-4 py-4 px-8 rounded">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <span className="float-left block text-safeFormGray text-sm">Select Plan</span>
                                                </Grid>
                                            </Grid>
                                            <div className="py-6">
												<div className="flex flex-wrap text-sm text-safeFormGray">
													<div style={{ width: '100%', alignItems: 'center', display: this.state.plans_spinner }}>
														<center><CircularProgress /></center>
													</div>
													{ this.listItems(this.state.plans) }
													<div key={'static'} className="p-2 w-6/12 lg:w-4/12 xl:w-3/12">
														<div className="border-2 rounded py-2 my-2">
															<div className='flex flex-col items-center'>
																<span className="py-1 md:py-2">Monthly Subscription</span>
																<button type="button" className="bg-primary w-32 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
																	<span className="text-xs">Comming soon</span>
																</button>
															</div>
														</div>
													</div>
												</div>
                                            </div>
                                        </div>
                                        
                                        <div className="border-2 my-4 py-4 px-8 rounded">
                                            <span className="float-left my-2 text-safeFormGray text-sm">Credit Card Info</span>
                                            <div className="flex-col">
												<div className="text-center">
													<Grid container spacing={2}>
														<Grid item xs={12} sm={12} md={12}>
															<div className="flex">
																<div className="flex-initial w-1/2 text-center pr-4">
																	<TextField
																		id="name_on_card"
																		label="Name on card"
																		type="text"
																		className="w-full"
																		value={this.state.name_on_card}
																		onChange={(event) => this.inputHandler(event, "name_on_card")}
																		variant="outlined"
																	/>
																</div>
																<div className="flex-initial w-1/2 text-center">
																	<TextField
																		id="card_number"
																		label="Card Number"
																		type="text"
																		className="w-full"
																		value={this.state.card_number}
																		onChange={(event) => this.inputHandler(event, "card_number")}
																		variant="outlined"
																	/>
																</div>
															</div>
														</Grid>
														<Grid item xs={12} sm={12} md={12}>
															<div className="flex">
																<div className="flex-initial w-1/2 text-center pr-4">
																	<MaskedInput
																		mask="11/11"
																		id="expiry_date"
																		name="expiry_date"
																		className="w-full pl-2 border-2 border-gray-300 text-black rounded"
																		style={{ height: '55px', fontSize: '16px' }}
																		value={this.state.expiry_date}
																		placeholder="MM/YY"
																		onChange={(event) => this.inputHandler(event, "expiry_date")}
																	/>
																</div>
																<div className="flex-initial w-1/2 text-center">
																	<TextField
																		type="tel"
																		id="cvv"
																		label="CVV"
																		className="w-full"
																		value={this.state.cvv}
																		onChange={(event) => this.inputHandler(event, "cvv")}
																		variant="outlined"
																	/>
																</div>
															</div>
														</Grid>
													</Grid>
												</div>
												<div className="text-center mt-4">
													<div className="flex-wrap sm:flex my-2 items-end">
														<div className="flex-1 text-safeFormGray text-sm">
															<span className="float-left my-2">Billing Address</span>
														</div>
														<div className="flex-1">
															<div className="float-none sm:float-right">
																<div className="items-center">
																	<div className="text-center text-safeFormGray text-xs py-2 float-left">
																		<PrimaryCheckbox
																		size="small"
																		checked={this.state.is_billing_address}
																		onChange={(event) => { (event.target.checked) ? (this.isValidUserAddress() ?
																			this.setCardAddressAsUserAddress() : this.setState({ snackMsg: "Please fill customer info first!!", snack: true, snackVariant: 'error' }))
																			: this.resetCardAddress() }}
																		// onChange={(event) => { (event.target.checked) ? this.state.edit_button_disabled) ? this.setState({ snackMsg: "You haven't selected any user!!", snack: true, snackVariant: 'error' }) : this.handleBillingCheckbox(event, 'is_billing_address') }}
																		inputProps={{ 'aria-label': 'billing address checkbox' }}/>
																		Same as customer info
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div>
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12}>
																<input
																	id="location"
																	placeholder="Search Address"
																	disabled={this.state.is_billing_address}
																	type="text"
																	margin="normal"
																	style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
																	ref={this.cardAddressReference}
																	variant="outlined"
																	className="w-full border-solid border border-gray-500 rounded"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<TextField
																	id="card_address"
																	label="Address"
																	type="text"
																	disabled={this.state.is_billing_address}
																	className="w-full"
																	value={this.state.card_address}
																	onChange={(event) => this.inputHandler(event, "card_address")}
																	variant="outlined"
																/>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-auto w-2/5 pr-2 text-center">
																		<TextField
																			id="card_city"
																			label="City"
																			type="text"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_city}
																			onChange={(event) => this.inputHandler(event, "card_city")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-auto w-1/5 pr-2 text-center">
																		<TextField
																			id="card_state"
																			label="State"
																			type="text"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_state}
																			onChange={(event) => this.inputHandler(event, "card_state")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-auto w-2/5 text-center">
																		<TextField
																			id="card_zip"
																			label="Zip"
																			type="tel"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_zip}
																			onChange={(event) => this.inputHandler(event, "card_zip")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-initial w-1/2 text-center pr-4">
																		<TextField
																			id="card_phone"
																			label="Phone #"
																			type="tel"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_phone}
																			onChange={(event) => this.inputHandler(event, "card_phone")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-initial w-1/2 text-center">
																		<TextField
																			id="crad_email"
																			label="Email"
																			type="email"
																			disabled={this.state.is_billing_address}
																			className="w-full"
																			value={this.state.card_email}
																			onChange={(event) => this.inputHandler(event, "card_email")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
														</Grid>
													</div>
												</div>
											</div>
                                        </div>
                                            
                                        <div className="flex">
											<div className="flex-1">
											</div>
											<div className="flex-1">
												<div className="float-none sm:float-right">
													<button type="reset" onClick={() => this.cancelSubmit()} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Cancel</button>
													<button type="submit" disabled={( (this.state.spinner === 'block') ? true : false )} className="bg-primary text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
														{(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Add Account' }
													</button>
												</div>
											</div>
                                    	</div>
                                    
                                    </form>
                                
                                </div>
                            </div>
                        </Paper>
					</div>
					
					<Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">
							<Grid container>
								<div className="flex flex-col w-full">
									<div className="text-center">
										<img className="h-8 mx-auto" src={successPng} alt="success-png"/>
									</div>
								</div>
							</Grid>
						</DialogTitle>
						<DialogContent>
							<span className="text-primary">Account added successfully!</span>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<div className="flex flex-col w-full">
									<div className="text-center">
										<button
											type="button"
											className="bg-primary w-24 text-white py-3 my-2 shadow-xl rounded text-base"
											onClick={() => this.handleClose()}>Done
										</button>
									</div>
								</div>
							</Grid>
						</DialogActions>
					</Dialog>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit, address, city, state, zip) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
				address: address,
				city: city,
				state: state,
				zip: zip,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAccount);