import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Header from './Header';
import UserHeader from './UserHeader';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import userPng from '../assets/user.png';
import invoicePng from '../assets/invoice.png';
import historyPng from '../assets/history.png';
import addPng from '../assets/add.png';
import clockPng from '../assets/clock.png';
import printPng from '../assets/print.png';
import clsx from 'clsx';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	roundedBox: {
		height: '200px',
		width: '200px'
	},
	boxIcon: {
		height: '40px',
		width: '40px'
	},
	balanceNumber: {
		textAlign: 'center',
		font: 'Bold Roboto',
		letterSpacing: '0px',
		textShadow: '0px 2px 5px #00000029',
		opacity: 1
	}
});

class MainMenu extends Component {

	constructor(props) {
		super(props)
		this.state = {
			user_name: '',
			first_name: '',
			credit: '',
			min_credit: '',
			credit_display: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
			password: '',
			confirm_password: '',
		}
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	componentWillMount() {
		// console.log('on main menu state : ',this.state);
		if (this.props.state.token === '' || this.props.state.user_type === 'Admin') {
			window.location.href = "/"
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true })
				this.props.UpdateMSG('')
			}
			this.fetchUserData()
		}
	}

	handleClickOpen = () => {
		// console.log("user id to set new pass : ",this.props.state.user_id);
		this.setState({ modalOpen: true })
	}

	handleClose = () => {
		this.setState({ modalOpen: false, spinner: 'none', password: '', confirm_password: '' })
	}

	setNewPassword = () => {
		// console.log("here : ",this.state.password,this.state.confirm_password);
		if (this.state.password == '') {
			this.setState({ snackMsg: "Password is Required.", snack: true })
		}
		else if (this.state.confirm_password == '') {
			this.setState({ snackMsg: "Confirm Password is Required.", snack: true })
		}
		else if (this.state.password !== this.state.confirm_password) {
			this.setState({ snackMsg: "Confirm Password isn't matched!!.", snack: true })
		}
		else {
			this.actionResetPasswordClick()
		}
	}

	async actionResetPasswordClick() {
		if (navigator.onLine) {
			var Data = {
				user_id: this.props.state.user_id,
				password: this.state.password,
				confirm_change_password: 1
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.SetNewPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						console.log(res)
						this.setState({ spinner: 'none', modalOpen: false })
						this.state.password = ''
						this.state.confirm_password = ''
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					//console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true })
					this.setState({ snackMsg: "Something went wrong.", snack: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	async fetchUserData() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetUserDetail + "/" + this.props.state.user_id, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						// console.log('result on main menu ::=>',res.data)
						let userData = res.data[0];
						const { phone_no, first_name, last_name, credit_balance, 
							address, city, state, zip } = userData;
						var credit;
						if (credit_balance != null && credit_balance != 'undefined') {
							credit = (credit_balance / 0.15).toFixed(0)
						}
						else {
							credit = 0.00
						}
						this.setState({ user_name: phone_no, first_name: first_name, last_name: last_name, credit: credit_balance, credit_display: credit })
						this.props.CustomerDetail(phone_no, first_name, last_name, credit_balance, address, city, state, zip)
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', button: '', snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	convertTime = (balance) => {
		// console.log('convertTime ::',balance)
		let givenSeconds = balance * 60; 
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes(); 
		let seconds = dateObj.getSeconds(); 
		return hours.toString().padStart(2, '0') 
			+ ':' + minutes.toString().padStart(2, '0') 
			+ ':' 
			+ seconds.toString().padStart(2, '0'); 
	}

	render() {
		const { classes } = this.props;
		if (this.props.state.token !== '') {
			return (
				<div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar>
					{/* {this.state.BUTTONS && <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none', fontSize: '16px' }} onClick={() => {
						{
							this.props.Logout()
							window.location.href = "/"
						}
					}}>Sign Out</Button>} */}

					{/* <Header propsState={this.props.state} /> */}
					<UserHeader propsState={this.props.state} />
					<div>
						<div className="p-2 sm:p-10">
						<div className='user-body-card px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden shadow-lg bg-white'>
								<p className="tracking-normal font-sans text-primary text-lg font-semibold mb-4 px-4">Welcome {this.state.first_name}</p>
								<div className="flex flex-col sm:flex-row">
									{/* user */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/UserProfile")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={userPng} className="inline mx-auto mt-6" alt="user-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Account Details</h6>
										</div>
									</div>

									{/* invoice */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/Billing")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={invoicePng} className="inline mx-auto mt-6" alt="invoice-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Billing</h6>
										</div>
									</div>

									{/* history */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/UserHistory")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={historyPng} className="inline mx-auto mt-6" alt="history-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Usage History</h6>
										</div>
									</div>

									{/* add time */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/Plans", {PropsData: { type: 'refill' } })}>
									{/* onClick={() => this.props.history.push("/ChoosePlan", { type: 'refill', credit_balance: this.state.credit })}> */}
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={addPng} className="inline mx-auto mt-6" alt="add-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Add Time</h6>
										</div>
									</div>
								</div>
							</div>
							<div className='user-body-card mt-12 px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden shadow-lg bg-white'>
								<div className="flex flex-col sm:flex-row">	
									<div className='sm:w-1/2 p-4'>
										<div className="flex flex-row">
											<div className="mb-3">
												<img src={clockPng} className="inline mx-auto w-3/5 md:w-4/5" alt="clock-png"/>
											</div>
											<div className="mb-3">
												<h3 className="tracking-normal mb-2 md:mb-4 font-sans text-primary text-sm md:text-xl">Time Remaining</h3>
												<button type="button" onClick={() => this.props.history.push("/Plans", {PropsData: { type: 'refill' } })} className="bg-primary text-white px-1 md:px-6 py-1 rounded font-sans text-sm md:text-xl">Add Time</button>
											</div>
										</div>
										<span className={clsx(classes.balanceNumber, 'text-4xl md:text-6xl text-center text-primary')}>{this.convertTime(this.state.credit_display)}</span>
									</div>
									<div className='sm:w-1/2 p-4'>
										<div className="flex flex-row">
											<div className="mb-3">
												<img src={printPng} className="inline mx-auto w-4/5" alt="print-png"/>
											</div>
											<div className="mb-3">
												<h3 className="tracking-normal mb-2 md:mb-4 font-sans text-primary text-sm md:text-xl">Prints Remaining</h3>
												<span className={clsx(classes.balanceNumber, 'text-center text-sm md:text-xl text-primary')}>B&W</span>
												<span className={clsx(classes.balanceNumber, 'ml-12 text-center text-sm md:text-xl text-primary')}>Color</span>
											</div>
										</div>
										<span className={clsx(classes.balanceNumber, 'text-center text-4xl md:text-6xl text-primary')}>{this.state.credit_display}</span>
										<span className={clsx(classes.balanceNumber, 'ml-12 text-center text-4xl md:text-6xl text-primary')}>{(this.state.credit_display / 7).toFixed(0)}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

MainMenu.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		CustomerDetail: (user_name, first_name, last_name, credit, address, city, state, zip) => {
			var value = { user_name, first_name, last_name, credit, address, city, state, zip }
			dispatch({ type: 'CustomerDetail', value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainMenu));
