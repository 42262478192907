import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Header from './Header';
import UserHeader from './UserHeader';
import AdminHeader from './AdminHeader';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import userPng from '../assets/user.png';
import invoicePng from '../assets/invoice.png';
import historyPng from '../assets/history.png';
import addPng from '../assets/add.png';
import clockPng from '../assets/clock.png';
import printPng from '../assets/print.png';
import lock from '../assets/lock.png';
import clsx from 'clsx';
import plansCardBgPng from '../assets/plans_card_bg.png';
import { InputBase, IconButton } from '@material-ui/core';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	paper: {
		padding: theme.spacing.unit * 2,
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	roundedBox: {
		height: '200px',
		width: '200px'
	},
	boxIcon: {
		height: '40px',
		width: '40px'
	},
	balanceNumber: {
		textAlign: 'center',
		font: 'Bold Roboto',
		letterSpacing: '0px',
		textShadow: '0px 2px 5px #00000029',
		opacity: 1
	}
});

class Plans extends Component {

	constructor(props) {
		super(props)
		this.state = {
			plans: [],
			user_name: '',
			first_name: '',
			credit: '',
			min_credit: '',
			credit_display: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
			password: '',
			confirm_password: '',
		}
	}

	componentDidMount() {
		// console.log('on plan state : ',this.props);
		if (this.props.state.token === '') {
			window.location.href = "/"
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true })
				this.props.UpdateMSG('')
			}
			this.fetchPlanListing();
			// this.fetchUserData()
		}
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						let planList = [];
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
								planList.push(element)
							})
						}
						this.setState({ plans: planList })
					}
					else {
						this.setState({ spinner: 'none', plans: [], snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', plans: [], snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	// async fetchUserData() {
	// 	if (navigator.onLine) {
	// 		this.setState({ spinner: 'block', BUTTONS: false })
	// 		return fetch(URLHelper.GetUserDetail + "/" + this.props.state.user_id, {
	// 			method: 'GET',
	// 			headers: {
	// 				'Accept': 'Application/json',
	// 				'Content-Type': 'application/json',
	// 				'authorization': 'Bearer ' + this.props.state.token
	// 			},
	// 		}).then((Response) => {
	// 			if (Response.status == 401) {
	// 				this.props.Logout()
	// 				window.location.href = "/"
	// 			}
	// 			Response.json().then((res) => {
	// 				this.setState({ spinner: 'none', BUTTONS: true })
	// 				if (res.status) {
	// 					// console.log('result on main menu ::=>',res.data)
	// 					var credit;
	// 					if (res.data[0].credit_balance != null && res.data[0].credit_balance != 'undefined') {
	// 						credit = (res.data[0].credit_balance / 0.15).toFixed(0)
	// 					}
	// 					else {
	// 						credit = 0.00
	// 					}
	// 					this.setState({ user_name: res.data[0].phone_no, first_name: res.data[0].first_name, credit: res.data[0].credit_balance, credit_display: credit })
	// 					this.props.CustomerDetail(res.data[0].phone_no, this.state.first_name, res.data[0].credit_balance, res.data[0].address, res.data[0].city, res.data[0].state, res.data[0].zip)

	// 				}
	// 				else {
	// 					this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
	// 				}
	// 			})
	// 				.catch((error) => {
	// 					this.setState({ spinner: 'none', button: '' })
	// 					this.setState({ snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
	// 				})
	// 		})
	// 	}
	// 	else {
	// 		this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
	// 	}
	// }
    
	listItems = (plansArray, refillType, action_by, user_id) => {
		// console.log('plans ::',plansArray)
        return (
		plansArray.map((data, index) =>
			<div key={data.plan_id} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
				<div className="plan-items-card bg-contain bg-top border py-2 my-2" style={{ backgroundImage: `url(${plansCardBgPng})` }}>
					{
						(data.bonus > 0) &&
						<div className="-mt-6" style={{ marginLeft: '90%'}}>
							<button type="button"
								className="bg-safeYellow h-10 w-10 p-1 text-white rounded-lg font-sans text-xs">
								Save <span className="text-smXs">{(data.bonus * 100 / data.price) % 1 != 0 ? (data.bonus * 100 / data.price).toFixed(2) : (data.bonus * 100 / data.price)}%</span>
							</button>
						</div>
					}
					<div className={clsx((data.bonus > 0) && '-mt-4', 'flex flex-col items-center')}>
						<span className="pt-4 pb-24 text-sm md:text-lg">{this.convertTime(data.price + data.bonus)}</span>
						{
							(this.state.selected_account_plan !== '' && this.state.selected_account_plan === data.plan_id) ?
							<button onClick={() => this.setState({selected_account_plan: ''})} type="button" className="bg-safeGreen w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
								<div className="flex flex-row items-center">
									{/* <img className="h-4" src={plansCardBgPng} alt="plansCardBgPng"/> */}
									<span className="pl-2">Selected</span>
								</div>
							</button>
							:
							<button onClick={() => { this.props.history.push("/PaymentMethod",
								{ PropsData:
									{ type: refillType, plan_id: data.plan_id, price: data.price, bonus: data.bonus, action_by, user_id} }
								)}} type="button" className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
								<span className="text-xs">$ </span>{ data.price.toFixed(2) }
							</button>
						}
					</div>
				</div>
			</div>
		));
	}

	convertTime = (amount) => {
        // console.log('convertTime ::',amount)
        let givenSeconds = (amount / 0.15) * 60;
        // console.log('givenSeconds ::',givenSeconds)
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        if((amount / 0.15) > 120){
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
        }else{
            return (amount / 0.15).toFixed(0) + ' Minutes'; 
        }
    }

	render() {
		// const { classes } = this.props;
		const { type, action_by, user_id } = this.props.location.state.PropsData;
		if (this.props.state.token !== '') {
			return (
				<div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar>

					{
						(this.props.state.admin_first_name) ?
						<AdminHeader propsState={this.props.state} /> :
						<UserHeader propsState={this.props.state} />
					}
					<div className={clsx((this.props.state.admin_first_name !== '') ? "mt-12" : "mt-40"," w-11/12 sm:w-9/12 rounded mx-auto")}>
						<div className="text-center">
						<span className="text-safeFormGray text-base mx-auto">
						{( type !== '' && type === 'refill') ? 'Choose Refill Amount' : 'Choose Auto Replenish Amount'}</span>
						</div>
						<div className="py-2">
							<div className="flex flex-wrap text-lg font-sans text-white">
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress /></center>
								</div>
								{ this.listItems(this.state.plans, type, action_by, user_id) }
								{
									(this.state.BUTTONS) &&
									<div key={'static'} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
										<div className="plan-items-card bg-contain bg-top border py-2 my-2" style={{backgroundImage: `url(${plansCardBgPng})` }}>
											<div className='flex flex-col items-center'>
												<span className="pt-4 pb-18 sm:pb-24 text-center text-sm md:text-lg">Monthly Subscription</span>
												<button type="button" className="bg-primary w-32 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
													<span className="text-xs">Comming soon</span>
												</button>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
					
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		// CustomerDetail: (user_name, first_name, credit, address, city, state, zip) => {
		// 	var value = {
		// 		user_name: user_name,
		// 		first_name: first_name,
		// 		credit: credit,
		// 		address: address,
		// 		city: city,
		// 		state: state,
		// 		zip: zip
		// 	}
		// 	dispatch({ type: 'CustomerDetail', value: value })
		// },
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
