import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AdminApproval from "./Pages/AdminApproval";
import ForgotPassword from "./Pages/ForgotPassword";
import SuccessForgotPassword from "./Pages/SuccessForgotPassword";
import SetNewPassword from "./Pages/SetNewPassword";
import AdminApprovalPayment from "./Pages/AdminApprovalPayment";
import ChoosePlan from "./Pages/ChoosePlan";
import Plans from "./Pages/Plans";
import ChooseItem from "./Pages/ChooseItem";
import ConfirmExistCCInfo from "./Pages/ConfirmExistCCInfo";
import EnterCCInfo from "./Pages/EnterCCInfo";
import EnterExistCCInfo from "./Pages/EnterExistCCInfo";
import LandingPage from "./Pages/LandingPage";
import AutoLogin from "./Pages/AutoLogin";
import MainMenu from "./Pages/MainMenu";
import PaymentMethod from "./Pages/PaymentMethod";
import RefillSuccess from "./Pages/RefillSuccess";
import ReplenishmentDone from "./Pages/ReplenishmentDone";
import SignUp from "./Pages/SignUp";
import UserProfile from "./Pages/UserProfile";
import UserCards from "./Pages/UserCards";
import UserHistory from "./Pages/UserHistory";
import CustomAmount from "./Pages/CustomAmount";
import AccountList from "./Pages/AccountList";
import AddNewAccount from "./Pages/AddNewAccount";
import Dummy from "./Pages/Dummy";
import UsageReportIframe from "./Pages/UsageReportIframe";
// import UsageReportChart from "./Pages/UsageReportChart";
import SalesReportIframe from "./Pages/SalesReportIframe";
// import SalesReportChart from "./Pages/SalesReportChart";
import ContactUs from "./Pages/ContactUs";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";

class App extends React.Component {

  // constructor(props) {
  //   super(props);
  // }

  onIdle = () => {
    // console.log('this.props :', this.props);
    if (this.props.state.user_id != "") {
      // console.log('inside user_id is not blank...');
      if (this.props.state.user_type != "Admin") {
        // console.log('inside user not admin...');
        this.props.timeout();
        window.location.replace("/");
      } else {
        // console.log('inside user is admin...');
      }
    } else {
      // console.log('inside user_id is blank...');
    }
  };

  render() {
    return (
      < Router >
        <div>
          <IdleTimer
            onIdle={this.onIdle}
            //5 min timeout
            timeout={1000 * 60}
          />
          {/* {console.log(this.props)} */}
          <Route exact path="/" component={LandingPage} />
          <Route
            exact
            path="/AutoLogin/:userPhone/:adminToken"
            component={AutoLogin}
          />
          <Route path="/AdminApproval" component={AdminApproval} />
          <Route path="/ForgotPassword" component={ForgotPassword} />
          <Route
            exact
            path="/SuccessForgotPassword"
            component={SuccessForgotPassword}
          />
          <Route
            path="/SetNewPassword"
            component={props => <SetNewPassword {...props} />}
          />
          <Route
            path="/AdminApprovalPayment"
            component={props => <AdminApprovalPayment {...props} />}
          />
          <Route
            path="/ChoosePlan"
            component={props => <ChoosePlan {...props} />}
          />
          <Route
            path="/Plans"
            component={props => <Plans {...props} />}
          />
          <Route
            path="/ChooseItem"
            component={props => <ChooseItem {...props} />}
          />
          <Route
            path="/ConfirmExistCCInfo"
            component={props => <ConfirmExistCCInfo {...props} />}
          />
          <Route
            path="/EnterCCInfo"
            component={props => <EnterCCInfo {...props} />}
          />
          <Route
            path="/EnterExistCCInfo"
            component={props => <EnterExistCCInfo {...props} />}
          />
          <Route path="/MainMenu" component={MainMenu} />
          <Route path="/AccountList" component={AccountList} />
          <Route path="/AddNewAccount" component={AddNewAccount} />
          <Route
            path="/PaymentMethod"
            component={props => <PaymentMethod {...props} />}
          />
          <Route
            path="/RefillSuccess"
            component={props => <RefillSuccess {...props} />}
          />
          <Route
            path="/ReplenishmentDone"
            component={props => <ReplenishmentDone {...props} />}
          />
          <Route path="/SignUp" component={props => <SignUp {...props} />} />
          <Route path="/CustomAmount" component={CustomAmount} />
          <Route path="/Dummy" component={Dummy} />
          <Route
            path="/UsageReportChart"
            component={props => <UsageReportIframe {...props} />}
          // component={props => <UsageReportChart {...props} />}
          />
          <Route
            path="/SalesReportChart"
            component={props => <SalesReportIframe {...props} />}
          // component={props => <SalesReportChart {...props} />}
          />
          <Route
            exact
            path="/SafeTechProfile/:userName/:adminToken"
            component={props => <UserProfile {...props} />} />
         
          <Route
            exact
            path="/Profile"
            component={props => <UserProfile {...props} />} />
          {/* <Route
            exact
            path="/SafeTechProfile/:userName/:adminToken"
            component={props => <SignUp {...props} />} /> */}
          {/* <Route
            exact
            path="/Profile"
            component={props => <SignUp {...props} />} /> */}
          <Route
            exact
            path="/UserProfile"
            component={props => <UserProfile {...props} />} />
          <Route
            exact
            path="/Billing"
            component={props => <UserCards {...props} />} />
          <Route
            exact
            path="/UserHistory"
            component={props => <UserHistory {...props} />} />
          <Route
            exact
            path="/ContactUs"
            component={props => <ContactUs {...props} />}
          />
          <Route
            exact
            path="/SafeTechContact/:userName/:adminToken"
            component={props => <ContactUs {...props} />}
          />
        </div>
      </Router >
    );
  }
}

const mapStateToProps = state => {
  return {
    state: state
  };
};

const mapDispatchToProps = dispatch => {
  return {
    timeout: () => dispatch({ type: "CustomerLogout" })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
