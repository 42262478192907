import React from 'react';
import { withRouter } from 'react-router-dom';
import logo from '../assets/logo-alt.png';

function PublicHeader(props) {
    // console.log('PublicHeader component :', props)
    return (
        <nav className="flex items-center justify-between flex-wrap p-6">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <img src={logo} className="inline h-20 ml-0 md:ml-6 my-6" alt="qisqam-logo"/>
            </div>
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div className="text-sm flex-none lg:flex-grow">
                    <span className="font-hairline text-white text-lg tracking-normal float-left mt-4 md:mt-0 md:float-right mr-6 font-sans">Don't have an account yet?</span>
                </div>
                <div>
                    <button type="button"
                    onClick={() => props.history.push("/SignUp")}
                    // onClick={() => props.history.push("/AdminApproval")}
                    className="text-sm px-6 py-2 mr-0 sm:mr-12 font-medium text-xl
                    transition ease-in duration-200
                    leading-normal border rounded border-transparent text-secondary bg-white hover:text-white hover:bg-secondary">
                    Sign up
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default withRouter(PublicHeader);
